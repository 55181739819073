<template>
  <div class="v-dialog">
    <el-dialog
      :title="title"
      :visible.sync="model"
      :width="width"
      :close-on-click-modal="closeOnClickModal"
      @close="close"
      @open="openDialog"
      :append-to-body="appendToBody"
      :fullscreen="fullscreen"
      :show-close="isShowClose"
    >
      <slot></slot>
      <slot name="footer">
        <span
          slot="footer"
          v-show="showFooterButton"
          :style="oStyle"
          class="dialog-footer"
        >
          <el-button v-if="showCancelButton" @click="cancel">{{
            cancelTxt
          }}</el-button>
          <el-button
            v-show="showConfirmButton"
            type="primary"
            :disabled="confirmDisabled"
            :loading="confirmLoading"
            @click="confirm"
            >{{ sureTxt }}</el-button
          >
        </span>
      </slot>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import { Dialog } from "element-ui";
Vue.use(Dialog);
export default {
  watch: {
    value(newValue) {
      this.model = newValue;
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
  components: {},
  props: {
    oStyle: {
      type: String,
      default: "justify-content: space-between;",
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "30%",
    },
    sureTxt: {
      type: String,
      default: "确 定",
    },
    cancelTxt: {
      type: String,
      default: "取 消",
    },
    confirmButtonColor: {
      type: String,
      default: "",
    },
    //是否显示取消按钮
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 点击遮罩层关闭弹窗
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    // 是否显示确定按钮
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    // 控制弹窗底部按钮显示隐藏
    showFooterButton: {
      type: Boolean,
      default: true,
    },
    // 是否为全屏 Dialog
    fullscreen: {
      type: Boolean,
      default: false,
    },
    // 是否显示关闭按钮
    isShowClose: {
      type: Boolean,
      default: true,
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    // beforeClose(action, done) {
    //   if (action === "confirm") {
    //     return done();
    //   } else {
    //     return done();
    //   }
    // },
    debouncedConfirm: _.debounce(function() {
      this.confirm();
    }, 1000, {
      leading: true,
      trailing: false
    }),
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.model = false;
      this.$emit("cancel");
    },
    close() {
      this.$emit("close");
    },
    openDialog() {
      this.$emit("open");
    },
  },
};
</script>

<style lang="less" scope>
.dialog-footer {
  display: flex;
}
</style>
