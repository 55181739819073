import CryptoJS from "crypto-js";

/**
 * @description 加解密
 */

const key = "H@M7e4o%dUb@JTw73ABR6OlrcR&dnR35";

const AESEcbEncrypt = (text) => {
  return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
};
const AESEcbDecrypt = (text) => {
  const result = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return result.toString(CryptoJS.enc.Utf8);
};

const setAESCbcEncrypt = (text) => {
  const cbcPkcs7Key = "H@M7e4o%dUb@JTwx";
  return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(cbcPkcs7Key), {
    iv: CryptoJS.enc.Utf8.parse(cbcPkcs7Key),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};
// 通过opts生成map
const generateMapByOpts = (opts) => {
  let map = {};
  if (Array.isArray(opts)) {
    opts.forEach((item) => {
      if (item.value !== undefined) {
        map[item.value] = item.label;
      }
    });
  } else {
    throw new Error("opts must be an array");
  }
  return map;
};

const optionsGenerator = (options) => {
  return (option) => {
    let _option = [];
    if (option) {
      if (option === 1) {
        _option.push({
          label: "全部",
          value: undefined,
        });
      } else if (option === 2) {
        _option.push({
          label: "请选择",
          value: undefined,
        });
      } else {
        _option = [options];
      }
    }
    Array.isArray(options) &&
      options.forEach((item) => {
        let tempObj = {};
        tempObj = { ...item };
        _option.push(tempObj);
      });
    return _option;
  };
};

const mapHelper = {
  setMap(options) {
    const map = generateMapByOpts(options);
    const setOps = optionsGenerator(options);
    return {
      map,
      setOps,
    };
  },
  setOptionGroup(list, params) {
    let { valueKey, labelKey, parentIdKey, defaultText, keys } = params;
    valueKey = valueKey || "id";
    labelKey = labelKey || "name";
    parentIdKey = parentIdKey || "parentId";

    let optionGroup = [];
    if (Array.isArray(list) && list.length) {
      let options = [];
      list.forEach((item) => {
        const parentId = item[parentIdKey];
        if ([undefined, null, ""].includes(parentId)) {
          optionGroup.push({
            label: item[labelKey],
            value: item[valueKey],
            options: [],
          });
        } else {
          options.push(item);
        }
      });
      options.forEach((option) => {
        const parentId = option[parentIdKey];
        const parentItem = optionGroup.find((item) => {
          return item.value === parentId;
        });
        const _option = {
          label: option[labelKey],
          value: option[valueKey],
        };
        if (keys && keys.length) {
          keys.forEach((key) => {
            _option[key] = option[key];
          });
        }
        parentItem.options.push(_option);
      });
    }

    if (defaultText) {
      optionGroup.unshift({
        label: defaultText,
        value: undefined,
        options: [
          {
            label: defaultText,
            value: undefined,
          },
        ],
      });
    }
    return optionGroup;
  },
};

export {
  generateMapByOpts,
  optionsGenerator,
  AESEcbEncrypt,
  AESEcbDecrypt,
  mapHelper,
  setAESCbcEncrypt,
};
