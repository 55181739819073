const routes = [
  //上报党组织信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportPartyOrg",
    name: "reportPartyOrg",
    meta: {
      title: "上报党组织信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPartyOrg/index.vue"], resolve),
  },
  //日志查看
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/baseDataViewLogReport",
    name: "baseDataViewLogReport",
    meta: {
      title: "日志查看",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPartyOrg/baseDataViewLogReport.vue"], resolve),
  },
  //导入上报党组织信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importPartyOrg",
    name: "importPartyOrg",
    meta: {
      title: "导入",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPartyOrg/importPartyOrg.vue",
      ], resolve),
  },
  //上报党员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportPartyMemberInfo",
    name: "reportPartyMemberInfo",
    meta: {
      title: "上报党员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPartyMemberInfo/index.vue",
      ], resolve),
  },
  //上报党员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importPartyMemberInfo",
    name: "importPartyMemberInfo",
    meta: {
      title: "导入党员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPartyMemberInfo/importPartyMemberInfo.vue",
      ], resolve),
  },
  //上报群团组织信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportGroupOrg",
    name: "reportGroupOrg",
    meta: {
      title: "上报群团组织信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGroupOrg/index.vue"], resolve),
  },
  //导入群团组织信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importGroupOrg",
    name: "importGroupOrg",
    meta: {
      title: "导入群团组织信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGroupOrg/importGroupOrg.vue",
      ], resolve),
  },
  //上报网格成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportGridPerson",
    name: "reportGridPerson",
    meta: {
      title: "上报网格成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGridPerson/index.vue",
      ], resolve),
  },
  //导入网格成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importGridPerson",
    name: "importGridPerson",
    meta: {
      title: "导入网格成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGridPerson/importGridPerson.vue",
      ], resolve),
  },
  //上报物业信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportPropertyInfo",
    name: "reportPropertyInfo",
    meta: {
      title: "上报物业信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPropertyInfo/index.vue",
      ], resolve),
  },
  //导入物业信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importPropertyInfo",
    name: "importPropertyInfo",
    meta: {
      title: "导入物业信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPropertyInfo/importPropertyInfo.vue",
      ], resolve),
  },
  //上报达人信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportInformedPerson",
    name: "reportInformedPerson",
    meta: {
      title: "上报达人信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportInformedPerson/index.vue",
      ], resolve),
  },
  //导入达人信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importInformedPerson",
    name: "importInformedPerson",
    meta: {
      title: "导入达人信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportInformedPerson/importInformedPerson.vue",
      ], resolve),
  },
  //上报社区运营商
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportOperatorInfo",
    name: "reportOperatorInfo",
    meta: {
      title: "上报社区运营商",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportOperatorInfo/index.vue",
      ], resolve),
  },
  //导入社区运营商
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importOperatorInfo",
    name: "importOperatorInfo",
    meta: {
      title: "导入社区运营商",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportOperatorInfo/importOperatorInfo.vue",
      ], resolve),
  },
  //上报群团成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportGroupPerson",
    name: "reportGroupPerson",
    meta: {
      title: "上报群团成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGroupPerson/index.vue",
      ], resolve),
  },
  //导入群团成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importGroupPerson",
    name: "importGroupPerson",
    meta: {
      title: "导入群团成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGroupPerson/importGroupPerson.vue",
      ], resolve),
  },
  //上报网格信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportGridInfo",
    name: "reportGridInfo",
    meta: {
      title: "上报网格信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGridInfo/index.vue"], resolve),
  },
  //导入网格信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importGridInfo",
    name: "importGridInfo",
    meta: {
      title: "导入网格信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportGridInfo/importGridInfo.vue",
      ], resolve),
  },
  //上报社区工作人员
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportCommunityWorkPerson",
    name: "reportCommunityWorkPerson",
    meta: {
      title: "上报社区工作人员",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportCommunityWorkPerson/index.vue",
      ], resolve),
  },
  //导入社区工作人员
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importCommunityWorkPerson",
    name: "importCommunityWorkPerson",
    meta: {
      title: "导入社区工作人员",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportCommunityWorkPerson/importCommunityWorkPerson.vue",
      ], resolve),
  },
  //上报社群信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportAssociationInfo",
    name: "reportAssociationInfo",
    meta: {
      title: "上报社群信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportAssociationInfo/index.vue",
      ], resolve),
  },
  //导入社群信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importAssociationInfo",
    name: "importAssociationInfo",
    meta: {
      title: "导入社群信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportAssociationInfo/importAssociationInfo.vue",
      ], resolve),
  },
  //上报微网格信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportMiniGrid",
    name: "reportMiniGrid",
    meta: {
      title: "上报微网格信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportMiniGrid/index.vue"], resolve),
  },
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importMiniGrid",
    name: "importMiniGrid",
    meta: {
      title: "导入微网格信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportMiniGrid/importMiniGrid.vue",
      ], resolve),
  },
  //上报业委会信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportOwnersCommitteeInfo",
    name: "reportOwnersCommitteeInfo",
    meta: {
      title: "上报业委会信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportOwnersCommitteeInfo/index.vue",
      ], resolve),
  },
  //导入业委会信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importOwnersCommitteeInfo",
    name: "importOwnersCommitteeInfo",
    meta: {
      title: "上报业委会信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportOwnersCommitteeInfo/importOwnersCommitteeInfo.vue",
      ], resolve),
  },
  //上报业委会成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/ownersCommitteeMemberInfo",
    name: "ownersCommitteeMemberInfo",
    meta: {
      title: "上报业委会成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/ownersCommitteeMemberInfo/index.vue",
      ], resolve),
  },
  //导入业委会成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importOwnersCommitteeMemberInfo",
    name: "importOwnersCommitteeMemberInfo",
    meta: {
      title: "导入业委会成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/ownersCommitteeMemberInfo/importOwnersCommitteeMemberInfo.vue",
      ], resolve),
  },
  //上报物业成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/reportPropertyMemberInfo",
    name: "reportPropertyMemberInfo",
    meta: {
      title: "上报物业成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPropertyMemberInfo/index.vue",
      ], resolve),
  },
  // //物业成员信息
  // {
  //   path: "provincePlatformDataUpReport/baseDataCommunity/reportPropertyMemberInfo",
  //   name: "reportPropertyMemberInfo",
  //   meta: {
  //     title: "上报物业成员信息",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPropertyMemberInfo/index.vue",
  //     ], resolve),
  // },
  //导入物业成员信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importPropertyMemberInfo",
    name: "importPropertyMemberInfo",
    meta: {
      title: "导入物业成员信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/reportPropertyMemberInfo/importPropertyMemberInfo.vue",
      ], resolve),
  },
  //上报物业与小区关系信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/propertyComplexRelation",
    name: "propertyComplexRelation",
    meta: {
      title: "上报物业与小区关系信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/propertyComplexRelation/index.vue",
      ], resolve),
  },
  //导入物业与小区关系信息
  {
    path: "provincePlatformDataUpReport/baseDataCommunity/importPropertyComplexRelation",
    name: "importPropertyComplexRelation",
    meta: {
      title: "导入物业与小区关系信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataCommunity/propertyComplexRelation/importPropertyComplexRelation.vue",
      ], resolve),
  },
  //上报商家店铺
  {
    path: "provincePlatformDataUpReport/baseDataPublic/shopInfo",
    name: "shopInfo",
    meta: {
      title: "上报商家店铺",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataPublic/shopInfo/index.vue"], resolve),
  },
  //导入商家店铺
  {
    path: "provincePlatformDataUpReport/baseDataPublic/importShopInfo",
    name: "importShopInfo",
    meta: {
      title: "导入商家店铺",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataPublic/shopInfo/importShopInfo.vue"], resolve),
  },
  //上报设备资源
  {
    path: "provincePlatformDataUpReport/baseDataPublic/resourceInfo",
    name: "resourceInfo",
    meta: {
      title: "上报设备资源",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataPublic/resourceInfo/index.vue"], resolve),
  },
  //导入设备资源
  {
    path: "provincePlatformDataUpReport/baseDataPublic/importResourceInfo",
    name: "importResourceInfo",
    meta: {
      title: "导入设备资源",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataPublic/resourceInfo/importResourceInfo.vue",
      ], resolve),
  },
  //上报人流量数据
  {
    path: "provincePlatformDataUpReport/baseDataPublic/personTraffic",
    name: "personTraffic",
    meta: {
      title: "上报人流量数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataPublic/personTraffic/index.vue"], resolve),
  },
  //导入人流量数据
  {
    path: "provincePlatformDataUpReport/baseDataPublic/importPersonTraffic",
    name: "importPersonTraffic",
    meta: {
      title: "导入人流量数据",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataPublic/personTraffic/importPersonTraffic.vue",
      ], resolve),
  },
  //上报物业收费信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/propertyCharge",
    name: "propertyCharge",
    meta: {
      title: "上报物业收费信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/propertyCharge/index.vue"], resolve),
  },
  //导入物业收费信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importPropertyCharge",
    name: "importPropertyCharge",
    meta: {
      title: "导入物业收费信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/propertyCharge/importPropertyCharge.vue",
      ], resolve),
  },
  //上报社区事件信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityEvent",
    name: "communityEvent",
    meta: {
      title: "上报社区事件信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityEvent/index.vue"], resolve),
  },
  //导入社区事件信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityEvent",
    name: "importCommunityEvent",
    meta: {
      title: "导入社区事件信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityEvent/importCommunityEvent.vue",
      ], resolve),
  },
  //上报社区活动
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityActivity",
    name: "communityActivity",
    meta: {
      title: "上报社区活动",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityActivity/index.vue",
      ], resolve),
  },
  //导入社区活动
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityActivity",
    name: "importCommunityActivity",
    meta: {
      title: "导入社区活动",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityActivity/importCommunityActivity.vue",
      ], resolve),
  },
  //上报社区投票信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityVote",
    name: "communityVote",
    meta: {
      title: "上报社区投票信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityVote/index.vue"], resolve),
  },
  //导入社区投票信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityVote",
    name: "importCommunityVote",
    meta: {
      title: "导入社区投票信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityVote/importCommunityVote.vue",
      ], resolve),
  },
  //上报社区工作信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityWork",
    name: "communityWork",
    meta: {
      title: "上报社区工作信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityWork/index.vue"], resolve),
  },
  //导入社区工作信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityWork",
    name: "importCommunityWork",
    meta: {
      title: "导入社区工作信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityWork/importCommunityWork.vue",
      ], resolve),
  },
  //上报业委会会议信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/ownersCommitteeMeeting",
    name: "ownersCommitteeMeeting",
    meta: {
      title: "上报业委会会议信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/ownersCommitteeMeeting/index.vue"], resolve),
  },
  //导入业委会会议信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importOwnersCommitteeMeeting",
    name: "importOwnersCommitteeMeeting",
    meta: {
      title: "导入业委会会议信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/ownersCommitteeMeeting/importOwnersCommitteeMeeting.vue",
      ], resolve),
  },
  //上报应用信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityAppInfo",
    name: "communityAppInfo",
    meta: {
      title: "上报应用信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityAppInfo/index.vue"], resolve),
  },
  //导入应用信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityAppInfo",
    name: "importCommunityAppInfo",
    meta: {
      title: "上报应用信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityAppInfo/importCommunityAppInfo.vue",
      ], resolve),
  },
  //上报应用日活信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityDayActiveInfo",
    name: "communityDayActiveInfo",
    meta: {
      title: "上报应用日活信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityDayActiveInfo/index.vue",
      ], resolve),
  },
  //导入应用日活信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityDayActiveInfo",
    name: "importCommunityDayActiveInfo",
    meta: {
      title: "导入应用日活信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityDayActiveInfo/importCommunityDayActiveInfo.vue",
      ], resolve),
  },
  //上报应用日活信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityIntegratedInfo",
    name: "communityIntegratedInfo",
    meta: {
      title: "上报应用日活信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityIntegratedInfo/index.vue",
      ], resolve),
  },
  //导入应用日活信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityIntegratedInfo",
    name: "importCommunityIntegratedInfo",
    meta: {
      title: "导入应用日活信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityIntegratedInfo/importCommunityIntegratedInfo.vue",
      ], resolve),
  },
  //上报社区用户信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityUserInfo",
    name: "communityUserInfo",
    meta: {
      title: "上报社区用户信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityUserInfo/index.vue",
      ], resolve),
  },
  //导入社区用户信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityUserInfo",
    name: "importCommunityUserInfo",
    meta: {
      title: "导入社区用户信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityUserInfo/importCommunityUserInfo.vue",
      ], resolve),
  },
  //上报平台积分信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/communityPlatformIntegral",
    name: "communityPlatformIntegral",
    meta: {
      title: "上报平台积分信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityPlatformIntegral/index.vue",
      ], resolve),
  },
  //导入平台积分信息
  {
    path: "provincePlatformDataUpReport/baseDataBusiness/importCommunityPlatformIntegral",
    name: "importCommunityPlatformIntegral",
    meta: {
      title: "导入平台积分信息",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/baseDataBusiness/communityPlatformIntegral/importCommunityPlatformIntegral.vue",
      ], resolve),
  },
  //社区总体情况
  {
    path: "provincePlatformDataUpReport/operateData/communityGeneral",
    name: "communityGenera",
    meta: {
      title: "社区总体情况",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/communityGeneral/index.vue"], resolve),
  },
  //查看日志
  {
    path: "provincePlatformDataUpReport/operateData/viewLog",
    name: "viewLog",
    meta: {
      title: "日志",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/provincePlatformDataUpReport/operateData/communityGeneral/viewLogReport.vue",
      ], resolve),
  },
  //基础人员信息
  {
    path: "provincePlatformDataUpReport/operateData/personnelInfo",
    name: "personnelInfo",
    meta: {
      title: "基础人员信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/personnelInfo/index.vue"], resolve),
  },

  //基础房地产信息
  {
    path: "provincePlatformDataUpReport/operateData/premises",
    name: "premises",
    meta: {
      title: "基础房地产信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/premises/index.vue"], resolve),
  },
  //物资信息
  {
    path: "provincePlatformDataUpReport/operateData/suppliesBase",
    name: "suppliesBase",
    meta: {
      title: "物资信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/suppliesBase/index.vue"], resolve),
  },
  //党建信息
  {
    path: "provincePlatformDataUpReport/operateData/partyOrg",
    name: "partyOrg",
    meta: {
      title: "党建信息",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/partyOrg/index.vue"], resolve),
  },
  //高频高质应用统计
  {
    path: "provincePlatformDataUpReport/operateData/appBase",
    name: "appBase",
    meta: {
      title: "高频高质应用统计",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/appBase/index.vue"], resolve),
  },
  //具体应用
  {
    path: "provincePlatformDataUpReport/operateData/appFrequency",
    name: "appFrequency",
    meta: {
      title: "具体应用",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/appFrequency/index.vue"], resolve),
  },
  //省级标配应用
  {
    path: "provincePlatformDataUpReport/operateData/provincialStandardApp",
    name: "provincialStandardApp",
    meta: {
      title: "省级标配应用",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/provincialStandardApp/index.vue"], resolve),
  },
  //治理机制数据
  {
    path: "provincePlatformDataUpReport/operateData/govern",
    name: "govern",
    meta: {
      title: "治理机制数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/govern/index.vue"], resolve),
  },
  //感受评价数据
  {
    path: "provincePlatformDataUpReport/operateData/sensoryEvaluation",
    name: "sensoryEvaluation",
    meta: {
      title: "感受评价数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/operateData/sensoryEvaluation/index.vue"], resolve),
  },
  //公约数据
  {
    path: "provincePlatformDataUpReport/futureNeighbor/convention",
    name: "convention",
    meta: {
      title: "公约数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureNeighbor/convention/index.vue"], resolve),
  },
  //积分统计
  {
    path: "provincePlatformDataUpReport/futureNeighbor/creditsStatistics",
    name: "creditsStatistics",
    meta: {
      title: "积分统计",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureNeighbor/creditsStatistics/index.vue"], resolve),
  },
  //积分兑换
  {
    path: "provincePlatformDataUpReport/futureNeighbor/creditsExchange",
    name: "creditsExchange",
    meta: {
      title: "积分兑换",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureNeighbor/creditsExchange/index.vue"], resolve),
  },
  //空间数据
  {
    path: "provincePlatformDataUpReport/futureNeighbor/spaceData",
    name: "spaceData",
    meta: {
      title: "空间数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureNeighbor/spaceData/index.vue"], resolve),
  },
  //社区学校招生
  {
    path: "provincePlatformDataUpReport/futureEducation/communitySchool",
    name: "communitySchool",
    meta: {
      title: "社区学校招生",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureEducation/communitySchool/index.vue"], resolve),
  },
  //社区受教育统计
  {
    path: "provincePlatformDataUpReport/futureEducation/educatedStatistics",
    name: "educatedStatistics",
    meta: {
      title: "社区受教育统计",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureEducation/educatedStatistics/index.vue"], resolve),
  },
  //教育配套
  {
    path: "provincePlatformDataUpReport/futureEducation/facilities",
    name: "facilities",
    meta: {
      title: "教育配套",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureEducation/facilities/index.vue"], resolve),
  },
  //共享书房
  {
    path: "provincePlatformDataUpReport/futureEducation/shareStudy",
    name: "shareStudy",
    meta: {
      title: "共享书房",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureEducation/shareStudy/index.vue"], resolve),
  },
  //幸福学堂
  {
    path: "provincePlatformDataUpReport/futureEducation/happySchool",
    name: "happySchool",
    meta: {
      title: "幸福学堂",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureEducation/happySchool/index.vue"], resolve),
  },
  //技能培训
  {
    path: "provincePlatformDataUpReport/futureEducation/skillTrain",
    name: "skillTrain",
    meta: {
      title: "技能培训",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureEducation/skillTrain/index.vue"], resolve),
  },
  //健康统计
  {
    path: "provincePlatformDataUpReport/futureHealth/healthStatistics",
    name: "healthStatistics",
    meta: {
      title: "健康统计",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureHealth/healthStatistics/index.vue"], resolve),
  },
  //养老服务
  {
    path: "provincePlatformDataUpReport/futureHealth/serviceOldAged",
    name: "serviceOldAged",
    meta: {
      title: "养老服务",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureHealth/serviceOldAged/index.vue"], resolve),
  },
  //健康配套
  {
    path: "provincePlatformDataUpReport/futureHealth/healthFacilities",
    name: "healthFacilities",
    meta: {
      title: "健康配套",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureHealth/healthFacilities/index.vue"], resolve),
  },
  //未来创业场景
  {
    path: "provincePlatformDataUpReport/futureCreative/scenario",
    name: "scenario",
    meta: {
      title: "未来创业场景",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureCreative/scenario/index.vue"], resolve),
  },
  //未来建筑场景
  {
    path: "provincePlatformDataUpReport/futureArchitecture/architectureScenario",
    name: "architectureScenario",
    meta: {
      title: "未来建筑场景",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureArchitecture/architectureScenario/index.vue"], resolve),
  },
  //建筑案例
  {
    path: "provincePlatformDataUpReport/futureArchitecture/architectureCase",
    name: "architectureCase",
    meta: {
      title: "建筑案例",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureArchitecture/architectureCase/index.vue"], resolve),
  },
  //未来交通场景
  {
    path: "provincePlatformDataUpReport/futureTraffic/trafficScenario",
    name: "trafficScenario",
    meta: {
      title: "未来交通场景",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureTraffic/trafficScenario/index.vue"], resolve),
  },
  //每日交通物流
  {
    path: "provincePlatformDataUpReport/futureTraffic/trafficLogistics",
    name: "trafficLogistics",
    meta: {
      title: "每日交通物流",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureTraffic/trafficLogistics/index.vue"], resolve),
  },
  //每日人员通行数据
  {
    path: "provincePlatformDataUpReport/futureTraffic/trafficData",
    name: "trafficData",
    meta: {
      title: "每日人员通行数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureTraffic/trafficData/index.vue"], resolve),
  },
  //未来低碳场景
  {
    path: "provincePlatformDataUpReport/futureLowCarbon/lowCarbonScenario",
    name: "lowCarbonScenario",
    meta: {
      title: "未来低碳场景",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureLowCarbon/lowCarbonScenario/index.vue"], resolve),
  },
  //资源循环利用
  {
    path: "provincePlatformDataUpReport/futureLowCarbon/lowCarbonResource",
    name: "lowCarbonResource",
    meta: {
      title: "资源循环利用",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureLowCarbon/lowCarbonResource/index.vue"], resolve),
  },
  //未来服务场景
  {
    path: "provincePlatformDataUpReport/futureService/serviceScenario",
    name: "serviceScenario",
    meta: {
      title: "未来服务场景",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureService/serviceScenario/index.vue"], resolve),
  },
  //增值服务
  {
    path: "provincePlatformDataUpReport/futureService/serviceIncrement",
    name: "serviceIncrement",
    meta: {
      title: "增值服务",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureService/serviceIncrement/index.vue"], resolve),
  },
  //事件数据
  {
    path: "provincePlatformDataUpReport/futureGovern/eventsData",
    name: "eventsData",
    meta: {
      title: "事件数据",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureGovern/eventsData/index.vue"], resolve),
  },
  //事件分类统计
  {
    path: "provincePlatformDataUpReport/futureGovern/eventsStatistics",
    name: "eventsStatistics",
    meta: {
      title: "事件分类统计",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureGovern/eventsStatistics/index.vue"], resolve),
  },
  //居民业务会
  {
    path: "provincePlatformDataUpReport/futureGovern/ownersCommittee",
    name: "ownersCommittee",
    meta: {
      title: "居民业务会",
    },
    component: (resolve) =>
      require(["@/views/setSystem/provincePlatformDataUpReport/futureGovern/ownersCommittee/index.vue"], resolve),
  },
];

export default routes;
