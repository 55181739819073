<template>
  <div class="vtag">
    <el-tag
      :type="type"
      :closable="closable"
      :disableTransitions="disableTransitions"
      :hit="hit"
      :color="color"
      :size="size"
      :effect="effect"
    >
      {{ text }}
    </el-tag>
  </div>
</template>

<script>
import { Tag } from "element-ui";
export default {
  name: "vTag",
  components: {
    elTag: Tag,
  },
  props: {
    text: String,
    type: String,
    closable: Boolean,
    disableTransitions: Boolean,
    hit: Boolean,
    color: String,
    size: String,
    effect: String,
  },
  methods: {
    // 点击 Tag 时触发的事件
    click() {
      this.$emit("cilck", this.text);
    },
    // 关闭 Tag 时触发的事件
    close() {
      this.$emit("close", this.text);
    },
  },
};
</script>

<style lang="less" scoped>
.vtag {
  display: inline-block;
}
</style>
