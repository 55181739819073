const routes = [
  {
    path: "futureTreat/epidemicPrevent",
    name: "epidemicPrevent",
    meta: {
      title: "防疫登记",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/index.vue"], resolve),
  },
  {
    path: "futureTreat/epidemicPreventForm",
    name: "epidemicPreventForm",
    meta: {
      title: "防疫登记表单",
      echo: "futureTreat/epidemicPrevent",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicPreventForm.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicPostList",
    name: "epidemicPostList",
    meta: {
      title: "防疫登记提交记录",
      echo: "futureTreat/epidemicPrevent",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicPostList.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicPostDetail",
    name: "epidemicPostDetail",
    meta: {
      title: "防疫登记提交记录详情",
      echo: "futureTreat/epidemicPrevent",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicPostDetail.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicInfo",
    name: "epidemicInfo",
    meta: {
      title: "防疫资讯",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicInfo.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicInfoForm",
    name: "epidemicInfoForm",
    meta: {
      title: "防疫资讯",
      echo: "futureTreat/epidemicInfo",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicInfoForm.vue",
      ], resolve),
  },
  /* 智慧党建 - 仅金钟社区 */
  {
    path: "futureTreat/stewardManageList",
    name: "stewardManageList",
    meta: {
      title: "管家管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/stewardManage/stewardManageList.vue",
      ], resolve),
  },
  {
    path: "futureTreat/stewardManageForm",
    name: "stewardManageForm",
    meta: {
      title: "管家管理表单",
      echo: "futureTreat/stewardManageList",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/stewardManage/stewardManageForm.vue",
      ], resolve),
  },
  {
    path: "futureTreat/stewardMessageManageList",
    name: "stewardMessageManageList",
    meta: {
      title: "管家通知管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/stewardMessageManage/stewardMessageManageList.vue",
      ], resolve),
  },
  // {
  //   path: "futureTreat/partyMemberManageList",
  //   name: "partyMemberManageList",
  //   meta: {
  //     title: "党员管理",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureTreat/partyMemberManage/partyMemberManageList.vue",
  //     ], resolve),
  // },
  // {
  //   path: "futureTreat/partyMemberManageForm",
  //   name: "partyMemberManageForm",
  //   meta: {
  //     title: "党员管理表单",
  //     echo: "futureTreat/partyMemberManageList",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureTreat/partyMemberManage/partyMemberManageForm.vue",
  //     ], resolve),
  // },

  {
    path: "futureTreat/neighborlyPoolList",
    name: "neighborlyPoolList",
    meta: {
      title: "睦邻汇",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/neighborlyPool/neighborlyPoolList.vue",
      ], resolve),
  },
  {
    path: "futureTreat/neighborlyPoolForm",
    name: "neighborlyPoolForm",
    meta: {
      title: "睦邻汇表单",
      echo: "futureTreat/neighborlyPoolForm",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/neighborlyPool/neighborlyPoolForm.vue",
      ], resolve),
  },

  {
    path: "futureTreat/factoryUnitList",
    name: "factoryUnitList",
    meta: {
      title: "联建单位",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/factoryUnit/factoryUnitList.vue"], resolve),
  },
  {
    path: "futureTreat/factoryUnitForm",
    name: "factoryUnitForm",
    meta: {
      title: "联建单位表单",
      echo: "futureTreat/factoryUnitForm",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/factoryUnit/factoryUnitForm.vue"], resolve),
  },

  {
    path: "futureTreat/onlineLearnList",
    name: "onlineLearnList",
    meta: {
      title: "在线学习",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/onlineLearn/onlineLearnList.vue"], resolve),
  },
  {
    path: "futureTreat/onlineLearnForm",
    name: "onlineLearnForm",
    meta: {
      title: "在线学习表单",
      echo: "futureTreat/onlineLearnForm",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/onlineLearn/onlineLearnForm.vue"], resolve),
  },

  {
    path: "futureTreat/vanguardTask",
    name: "vanguardTask",
    meta: {
      title: "先锋任务",
      echo: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/jinzhongParty/vanguardTask.vue"], resolve),
  },
  {
    path: "futureTreat/vanguardTaskForm",
    name: "vanguardTaskForm",
    meta: {
      title: "先锋任务",
      echo: "futureTreat/vanguardTask",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/jinzhongParty/vanguardTaskForm.vue",
      ], resolve),
  },
  {
    path: "futureTreat/vanguardTaskApplicants",
    name: "vanguardTaskApplicants",
    meta: {
      title: "先锋任务",
      echo: "futureTreat/vanguardTask",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/jinzhongParty/vanguardTaskApplicants.vue",
      ], resolve),
  },
  {
    path: "futureTreat/jinlinOnline",
    name: "jinlinOnline",
    meta: {
      title: "金邻在线",
      echo: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/jinzhongParty/jinlinOnline.vue"], resolve),
  },
  {
    path: "futureTreat/jinlinOnlineForm",
    name: "jinlinOnlineForm",
    meta: {
      title: "金邻在线",
      echo: "futureTreat/jinlinOnline",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/jinzhongParty/jinlinOnlineForm.vue",
      ], resolve),
  },
  {
    path: "futureTreat/jinlinOnlineApplicants",
    name: "jinlinOnlineApplicants",
    meta: {
      title: "金邻在线",
      echo: "futureTreat/jinlinOnline",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/jinzhongParty/jinlinOnlineApplicants.vue",
      ], resolve),
  },
  /* end */
];

export default routes;
