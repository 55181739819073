<template>
  <div class="v-control v-select">
    <label v-if="label" v-text="label"></label>
    <el-select
      v-bind="{ ...bind }"
      v-model="model"
      :multiple="multiple"
      :filterable="filterable"
      :allow-create="allowCreate"
      :placeholder="placeholder"
      :clearable="clearable"
      :disabled="disabled"
      :collapse-tags="collapseTags"
      @clear="clear"
      @remove-tag="remove"
    >
      <el-option
        v-for="item in options"
        :key="item.value + item.label"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import Vue from "vue";
import { Select } from "element-ui";
Vue.use(Select);
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    nativeProps: {},
    size: {
      type: String,
      default: "mini",
    },
    //是否折叠
    collapseTags: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array, Boolean],
      default: "",
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    // 输入框宽度
    width: {
      type: Number,
      default: 188,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      inputWidth: this.width,
      model: this.value,
      bind: {},
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
  created() {
    this.bind = {
      style: {
        width: this.inputWidth + "px",
      },
    };
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
    clear() {
      this.$emit("clear");
      console.log("清除内容");
    },
    remove(value) {
      this.$emit("remove", value);
    },
  },
};
</script>
<style lang="less">
.v-select {
  label {
    font-size: 14px;
    columns: #999;
    margin-right: 10px;
  }
}
.el-select-dropdown.el-popper {
  max-width: 450px;
}
</style>
