const routes = [
  {
    path: "futureHealth/recordSport",
    name: "recordSport",
    meta: {
      title: "运动打卡",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/recordSport/index.vue"], resolve),
  },
  {
    path: "setSystem/equipmentApply",
    name: "equipmentApply",
    meta: {
      title: "设备申请",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/equipmentApply/index.vue"], resolve),
  },
  // 金钟健康心
  {
    path: "futureHealth/foodManage",
    name: "foodManage",
    meta: {
      title: "菜品管理",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/healthyMind/foodManage.vue"], resolve),
  },
  {
    path: "futureHealth/seniorManage",
    name: "seniorManage",
    meta: {
      title: "老年人管理",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/healthyMind/seniorManage.vue"], resolve),
  },
  {
    path: "futureHealth/healthyMindMeals",
    name: "healthyMindMeals",
    meta: {
      echo: "futureHealth/seniorManage",
      title: "用餐情况",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/healthyMind/healthyMindMeals.vue",
      ], resolve),
  },
  //食堂订单
  {
    path: "futureHealth/communityCanteenOrder",
    name: "communityCanteenOrder",
    meta: {
      title: "食堂订单",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/healthyMind/communityCanteenOrder.vue",
      ], resolve),
  },

  /* 养老手环 - 金钟社区、青云社区 */
  {
    path: "futureHealth/elderCareBraceletList",
    name: "elderCareBraceletList",
    meta: {
      title: "手环管理",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/elderCareBracelet/index.vue"], resolve),
  },
  {
    path: "futureHealth/elderCareBraceletImport",
    name: "elderCareBraceletImport",
    meta: {
      echo: "futureHealth/elderCareBraceletList",
      title: "养老手环导入",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/elderCareBracelet/import.vue"], resolve),
  },
  /* end */
];

export default routes;
