const routes = [
  {
    path: "other/bannerList",
    name: "bannerList",
    meta: {
      title: "运营位列表",
    },
    component: (resolve) =>
      require(["@/views/other/banner/bannerList.vue"], resolve),
  },
  {
    path: "other/bannerForm",
    name: "bannerForm",
    meta: {
      echo: "other/bannerList",
      title: "运营位表单",
    },
    component: (resolve) =>
      require(["@/views/other/banner/bannerForm.vue"], resolve),
  },
  {
    path: "other/createProtol",
    name: "createProtol",
    meta: {
      title: "生成协议",
    },
    component: (resolve) =>
      require(["@/views/other/createProtol.vue"], resolve),
  },
  {
    path: "other/oIframe",
    name: "oIframe",
    meta: {
      title: "第三方",
      fromId: "第三方",
    },
    component: (resolve) =>
      require(["@/views/other/oIframe/index.vue"], resolve),
  },
  {
    path: "other/workPlatForm",
    name: "workPlatForm",
    meta: {
      title: "工作台",
      fromId: "工作台",
    },
    component: (resolve) =>
      require(["@/views/other/workPlatForm/index.vue"], resolve),
  },
];

export default routes;
