<template>
  <div :class="['areaSelect', { isFlex: isFlex }]">
    <v-select
      :options="userTypeOptions"
      v-model="searchParam.specifyCollectionTypes"
      label="用户类别"
      :disabled="disabled"
      @change="changeUserType"
    />
    <v-select
      v-for="(item, index) in areaItemList"
      clearable
      :key="index"
      filterable
      :multiple="false"
      :allowCreate="true"
      :options="item.list"
      v-model="item.roomId"
      @change="changeRoomId($event, item, index)"
      :label="item.title"
    />
  </div>
</template>

<script>
import { getAreaListUrl } from "./api.js";
import {
  relationOptions,
  relationOptionsMap,
  userTypeOptionsMap,
  userTypeOptions,
} from "./map";

export default {
  name: "areaSelect",
  data() {
    return {
      userTypeOptions,
      searchParam: {
        specifyCollectionTypes: this.specifyCollectionTypes,
        spaceId: "",
      },
      bstop: 1,
      areaItemList: [],
    };
  },
  watch: {},
  props: {
    // 组件当前层级
    isFlex: {
      type: Boolean,
      default: true,
    },
    specifyCollectionTypes: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否显示与业主关系
    isShowRelation: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  mounted() {
    if (this.specifyCollectionTypes) {
      this.changeUserType();
    }
  },
  destroyed() {},
  computed: {},
  methods: {
    async changeRoomId($event, item, index) {
      console.log($event, item, index);
      if (item.roomId) {
        this.areaItemList.splice(index + 1);
        await this.getBuildList($event);
      } else {
        this.areaItemList.splice(index + 1);
      }
      this.searchParam.spaceId = item.roomId;
      this.$emit("changeModel", this.searchParam);
      console.log(this.searchParam.spaceId, "--==");
    },
    changeUserType() {
      this.searchParam.spaceId = "";
      this.$emit("changeModel", this.searchParam);
      this.areaItemList = [];
      this.getBuildList();
    },
    async getBuildList(parentId) {
      if (this.bstop != 1) {
        return;
      }
      this.bstop = 0;
      let url = getAreaListUrl;
      let params = {
        parentId: parentId,
        specifyCollectionTypes: this.searchParam.specifyCollectionTypes,
      };
      let res = await this.$axios.get(url, { params: params });
      this.bstop = 1;
      if (res.code === 200) {
        if (res.data && Object.keys(res.data).length != 0) {
          let obj = res.data;
          res.data.list.forEach((ele) => {
            ele.label = ele.text;
            ele.value = ele.code;
          });
          this.areaItemList.push(res.data);
          this.searchParam.isLast = "";
        } else {
          this.areaItemList[this.areaItemList.length - 1]["isLast"] = 1;
          this.searchParam.isLast = 1;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.areaSelect {
  .v-control {
    margin: 0 10px 10px 0;
  }
}
.isFlex {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}
</style>
