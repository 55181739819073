<template>
  <div class="v-upload">
    <el-upload
      ref="uploadExcel"
      :action="uploadImgUrl"
      :limit="limitNum"
      :headers="{
        ...defaultHeaders,
        ...headers,
      }"
      accept=".xlsx,.doc,.docx,.pdf"
      :before-upload="beforeUploadFile"
      :on-change="fileChange"
      :on-exceed="exceedFile"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-remove="handleRemove"
      :file-list="fileList"
      :data="otherParams"
    >
      <el-button size="small" plain>选择文件</el-button>
    </el-upload>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // 请求地址
      limitNum: 1,
      formLabelWidth: "80px",
      form: {
        file: "",
      },
      fileList: [],
    };
  },
  props: {
    //携带其他参数
    otherParams: {
      type: Object,
      default() {
        return {};
      },
    },
    // 请求头
    headers: {
      type: Object,
      default() {
        return {};
      },
    },
    // v-model 绑定值——图片地址，多张图片用 “,” 分隔，图片地址需为绝对路径
    imgUrls: {
      type: String,
      default: "",
    },
    uploadImgUrl: {
      type: String,
      default: "/gateway/blade-resource/oss/endpoint/put-file",
    },
  },
  computed: {
    ...mapState({
      authorization: (state) => state.authorization,
      userInfo: (state) => state.app.userInfo,
    }),
    defaultHeaders() {
      let auth = this.userInfo.accessToken;
      let type = this.userInfo.tokenType;
      return {
        Authorization: this.authorization,
        "hc-auth": `${type} ${auth}`,
      };
    },
  },
  watch: {
    imgUrls: {
      handler(newValue) {
        console.log(newValue, "newValue");
        if (newValue) {
          let arr = newValue.split(",");
          // this.fileList = arr.map((v) => ({ url: v, status: "success" }));
          const fileUrls = this.fileList.map((v) => v.url);
          arr.map((v) => {
            console.log(v, fileUrls.includes(v));
            if (!fileUrls.includes(v)) {
              this.fileList.push({ url: v, status: "success" });
            }
          });
        } else {
          this.fileList = [];
        }
      },
      immediate: true,
    },
    fileList: {
      handler(val) {
        console.log(val, "vajdfka");
        const list = val.filter((v) => v.status === "success");
        this.updateFile(list);
      },
      deep: true,
    },
  },
  methods: {
    handleRemove(data) {
      // const imgUrlsArr = this.imgUrls.split(",").filter((v) => v !== url);
      // const imgUrls = imgUrlsArr.length ? imgUrlsArr.join(",") : "";
      // 删除符合回调函数 的数组元素
      let deepList = _.cloneDeep(this.fileList);
      _.remove(deepList, function (v) {
        return v.uid === data.uid;
      });
      this.fileList = deepList;
      console.log(this.fileList, "1241");
      // this.fileList.splice(index, 1);
      // this.$emit("update:imgUrls", imgUrls);
    },
    updateFile(val) {
      console.log(879988, val);
      if (this.limitNum == 1) {
        // let urlArr = val.map((item) => item.oUrl);
        if (val.length > 0) {
          // @arg imgUrls 图片列表 双向绑定 limit为1时String，>1时Array
          val[0].url && this.$emit("update:imgUrls", val[0].url);
        } else {
          this.$emit("update:imgUrls", "");
        }
      } else {
        let urlArr = [];
        val.forEach((item) => {
          // 文件状态为成功，代表已上传文件
          urlArr.push(item.url);
        });
        let urlStr = urlArr.join(",");
        this.$emit("update:imgUrls", urlStr);
      }
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning({
        title: "警告",
        message: `只能选择 ${this.limitNum} 个文件，当前共选择了 ${
          files.length + fileList.length
        } 个`,
      });
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      this.fileList = fileList;
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      console.log("before upload");
      console.log(file);
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (
        extension !== "xlsx" &&
        extension !== "pdf" &&
        extension !== "doc" &&
        extension !== "docx"
      ) {
        this.$message.warning({
          title: "警告",
          message: `只能上传xlsx，pdf，doc，docx类文件`,
        });
      }
      if (size > 10) {
        this.$message.warning({
          title: "警告",
          message: `文件大小不得超过10M`,
        });
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      if (response.code == 200) {
        this.$message.success({
          message: response.msg,
        });
      } else {
        this.$message.error({
          message: response.msg,
        });
      }
      fileList.forEach((item) => {
        if (file.uid == item.uid) {
          item.url = response.data.link;
        }
      });
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      let errorStr = err.toString();
      errorStr = errorStr.replace("Error: ", "");
      let errorObj = JSON.parse(errorStr);
      if (errorObj.code == "401") {
        this.$router.push({
          name: "login",
        });
      }
      this.$message.error({
        title: "错误",
        message: `文件上传失败`,
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
