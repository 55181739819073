<template>
  <div class="v-control v-date-picker">
    <label v-if="label" v-text="label"></label>
    <el-date-picker
      :style="{
        width: `${width}px`,
      }"
      v-model="model"
      :type="type"
      :disabled="disabled"
      :format="format"
      :value-format="formatValue"
      range-separator="至"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :placeholder="placeholder"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
  </div>
</template>

<script>
import Vue from "vue";
import { DatePicker } from "element-ui";
Vue.use(DatePicker);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  components: {},
  props: {
    pickerOptions: {
      type: Object,
      default: function () {
        return {
          disabledDate() {},
        };
      },
    },
    // 按钮宽度
    width: Number,
    placeholder: {
      type: String,
    },
    startPlaceholder: {
      type: String,
      default: "开始日期",
    },
    endPlaceholder: {
      type: String,
      default: "结束日期",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 左侧文本
    label: {
      type: String,
      default: "",
    },
    // 控件类型
    type: {
      type: String,
      default: "daterange",
    },
    // 显示在输入框中的格式
    format: String,
    // 可选，绑定值的格式。不指定则绑定值为 Date 对象
    formatValue: String,
    value: {
      type: [Array, String],
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
<style lang="less">
.v-date-picker {
  > label {
    margin-right: 10px;
    // color: #333;
    font-size: 14px;
  }
}
</style>
