const routes = [
  // 转派记录
  {
    path: "futureService/transferRecord",
    name: "transferRecord",
    meta: {
      title: "转派记录",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/workOrderManagement/transferRecord",
      ], resolve),
  },
  // 工单订单
  {
    path: "futureService/workOrder",
    name: "workOrder",
    meta: {
      title: "工单订单",
    },
    component: (resolve) =>
      require(["@/views/futureService/workOrderManagement/workOrder"], resolve),
  },
  // 工单类型
  {
    path: "futureService/workOrderType",
    name: "workOrderType",
    meta: {
      title: "工单类型",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/workOrderManagement/workOrderType",
      ], resolve),
  },
  // 人员配置
  {
    path: "futureService/staffing",
    name: "staffing",
    meta: {
      title: "人员配置",
    },
    component: (resolve) =>
      require(["@/views/futureService/workOrderManagement/staffing"], resolve),
  },
  //积分
  {
    path: "futureService/pointRecordList",
    name: "pointRecordList",
    meta: {
      title: "积分",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/bonusPointSetting/pointRecordList",
      ], resolve),
  },
  //积分配置
  {
    path: "futureService/bonusPointSettingList",
    name: "bonusPointSettingList",
    meta: {
      title: "积分配置",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/bonusPointSetting/bonusPointSettingList",
      ], resolve),
  },
  // 商户管理
  {
    path: "futureService/merchantList",
    name: "merchantList",
    meta: {
      title: "商户管理",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/merchantList"], resolve),
  },
  {
    path: "futureService/goodsList",
    name: "goodsList",
    meta: {
      title: "商户列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/goodsList"], resolve),
  },
  {
    path: "futureService/merchantForm",
    name: "merchantForm",
    meta: {
      echo: "futureService/merchantList",
      title: "商户详情",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/merchantForm"], resolve),
  },
  // 商品
  // {
  //   path: "futureService/goodsList",
  //   name: "goodsList",
  //   meta: {
  //     title: "商品列表",
  //   },
  //   component: (resolve) =>
  //     require(["@/views/futureService/pointsMall/goodsList"], resolve),
  // },
  {
    path: "futureService/goodsEdit",
    name: "goodsEdit",
    meta: {
      echo: "futureService/goodsList",
      title: "商品编辑",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/goodsEdit"], resolve),
  },
  {
    path: "futureService/pointsMallOrderList",
    name: "pointsMallOrderList",
    meta: {
      title: "兑换订单列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/pointsMall/pointsMallOrderList",
      ], resolve),
  },
  //场地
  {
    path: "futureService/placeTypeList",
    name: "placeTypeList",
    meta: {
      title: "场地类型列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reservationPlace/placeTypeList",
      ], resolve),
  },
  {
    path: "futureService/placeList",
    name: "placeList",
    meta: {
      title: "场地管理列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/reservationPlace/placeList"], resolve),
  },
  {
    path: "futureService/placeEdit",
    name: "placeEdit",
    meta: {
      echo: "futureService/placeList",
      title: "场地编辑",
    },
    component: (resolve) =>
      require(["@/views/futureService/reservationPlace/placeEdit"], resolve),
  },
  {
    path: "futureService/placeOrderList",
    name: "placeOrderList",
    meta: {
      title: "场地订单列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reservationPlace/placeOrderList",
      ], resolve),
  },
  // 广播站
  {
    path: "futureService/broadcastList",
    name: "broadcastList",
    meta: {
      title: "广播站列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/broadcastList.vue"], resolve),
  },
  {
    path: "futureService/signList",
    name: "signList",
    meta: {
      echo: "futureService/broadcastList",
      title: "广播签收列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/signList.vue"], resolve),
  },
  {
    path: "futureService/broadcastForm",
    name: "broadcastForm",
    meta: {
      echo: "futureService/broadcastList",
      title: "广播站表单",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/broadcastForm.vue"], resolve),
  },
  // 问卷调查
  {
    path: "futureService/questionnaireList",
    name: "questionnaireList",
    meta: {
      title: "问卷调查列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionnaireList.vue",
      ], resolve),
  },
  {
    path: "futureService/questionnaireEdit",
    name: "questionnaireEdit",
    meta: {
      echo: "futureService/questionnaireList",
      title: "问卷调查表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionnaireEdit.vue",
      ], resolve),
  },
  {
    path: "futureService/questionAnswerList",
    name: "questionAnswerList",
    meta: {
      echo: "futureService/questionnaireList",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionAnswerList.vue",
      ], resolve),
  },
  // 投票
  {
    path: "futureService/vote",
    name: "vote",
    meta: {
      title: "投票",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/index.vue"], resolve),
  },
  // 房屋产权人审核
  {
    path: "futureService/housePropertyOwnerExamine",
    name: "housePropertyOwnerExamine",
    meta: {
      title: "房屋产权人审核",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/vote/housePropertyOwnerExamine.vue",
      ], resolve),
  },
  // 生活通管理
  {
    path: "futureService/lifeExpert",
    name: "lifeExpert",
    meta: {
      title: "生活通管理",
    },
    component: (resolve) =>
      require(["@/views/futureService/lifeExpert/index.vue"], resolve),
  },
  {
    path: "futureService/votePeopleList",
    name: "votePeopleList",
    meta: {
      title: "投票参与人",
      echo: "futureService/vote",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/votePeopleList.vue"], resolve),
  },
  {
    path: "futureService/voteForm",
    name: "voteForm",
    meta: {
      title: "投票",
      echo: "futureService/vote",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/voteForm.vue"], resolve),
  },
  //党建
  {
    path: "futureService/onLineStudy",
    name: "onLineStudy",
    meta: {
      title: "在线学习管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/onLineStudy/index.vue",
      ], resolve),
  },
  {
    path: "futureService/onLineStudyForm",
    name: "onLineStudyForm",
    meta: {
      title: "在线学习管理",
      echo: "futureService/onLineStudy",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/onLineStudy/onLineStudyForm.vue",
      ], resolve),
  },
  {
    path: "futureService/classScheduleCard",
    name: "classScheduleCard",
    meta: {
      title: "课程表管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/classScheduleCard/index.vue",
      ], resolve),
  },
  {
    path: "futureService/teacherLibrary",
    name: "teacherLibrary",
    meta: {
      title: "师资库管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/teacherLibrary/index.vue",
      ], resolve),
  },
  {
    path: "futureService/partyMember",
    name: "partyMember",
    meta: {
      title: "党员管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyMember/index.vue",
      ], resolve),
  },
  {
    path: "futureService/partyMemberForm",
    name: "partyMemberForm",
    meta: {
      title: "党员管理",
      echo: "futureService/partyMember",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyMember/partyMemberForm.vue",
      ], resolve),
  },
  {
    path: "futureService/partyInfo",
    name: "partyInfo",
    meta: {
      title: "党建资讯",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyInfo/index.vue",
      ], resolve),
  },
  {
    path: "futureService/partyInfoForm",
    name: "partyInfoForm",
    meta: {
      title: "党建资讯",
      echo: "futureService/partyInfo",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyInfo/partyInfoForm.vue",
      ], resolve),
  },
  {
    path: "futureService/rightsComment",
    name: "rightsComment",
    meta: {
      title: "民主评议",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/rightsComment/index.vue",
      ], resolve),
  },
  {
    path: "futureService/rightsCommentForm",
    name: "rightsCommentForm",
    meta: {
      title: "民主评议",
      echo: "futureService/rightsComment",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/rightsComment/rightsCommentForm.vue",
      ], resolve),
  },
  {
    path: "futureService/canteenManage",
    name: "canteenManage",
    meta: {
      title: "食堂管理",
      echo: "futureService/canteenManage",
    },
    component: (resolve) =>
      require(["@/views/futureService/canteenManage/index.vue"], resolve),
  },
  {
    path: "futureService/canteenOrder",
    name: "canteenOrder",
    meta: {
      title: "食堂订单",
      echo: "futureService/canteenOrder",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/canteenManage/canteenOrder.vue",
      ], resolve),
  },
  {
    path: "futureService/setMenu",
    name: "setMenu",
    meta: {
      title: "设置菜品",
      echo: "futureService/setMenu",
    },
    component: (resolve) =>
      require(["@/views/futureService/canteenManage/setMenu.vue"], resolve),
  },
  {
    path: "futureService/canteenImport",
    name: "canteenImport",
    meta: {
      title: "导入套餐",
      echo: "futureService/canteenImport",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/canteenManage/canteenImport.vue",
      ], resolve),
  },
  {
    path: "futureService/propertyCompanyManagement",
    name: "propertyCompanyManagement",
    meta: {
      title: "物业公司管理",
      echo: "futureService/propertyCompanyManagement",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/propertyCompanyManagement/index.vue",
      ], resolve),
  },
  {
    path: "futureService/propertyTopic",
    name: "propertyTopic",
    meta: {
      title: "物业公告管理",
      echo: "futureService/propertyTopic",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/propertyCompanyManagement/propertyTopic.vue",
      ], resolve),
  },
  {
    path: "futureService/propertyTopicForm",
    name: "propertyTopicForm",
    meta: {
      echo: "futureService/propertyTopic",
      title: "超话区表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/propertyCompanyManagement/propertyTopicForm.vue",
      ], resolve),
  },
  // 闲置交易-好物管理
  {
    path: "futureService/goodMaterialManagement",
    name: "goodMaterialManagement",
    meta: {
      echo: "futureService/goodMaterialManagement",
      title: "好物管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/IdleTransaction/goodMaterialManagement.vue",
      ], resolve),
  },
  // 闲置交易-求购管理
  {
    path: "futureService/purchasingManagement",
    name: "purchasingManagement",
    meta: {
      echo: "futureService/purchasingManagement",
      title: "求购管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/IdleTransaction/purchasingManagement.vue",
      ], resolve),
  },
  // 闲置交易-评论管理
  {
    path: "futureService/purchasingCommentsManagement",
    name: "purchasingCommentsManagement",
    meta: {
      echo: "futureService/purchasingManagement",
      title: "评论管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/IdleTransaction/purchasingCommentsManagement.vue",
      ], resolve),
  },
  // 南溪阿姐-阿姐审核
  {
    path: "futureService/sisterExamine",
    name: "sisterExamine",
    meta: {
      title: "阿姐审核",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/sisterExamine.vue",
      ], resolve),
  },
  // 南溪阿姐-活动审核
  {
    path: "futureService/sisterActivityAuditList",
    name: "sisterActivityAuditList",
    meta: {
      title: "活动审核",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/sisterActivityAuditList.vue",
      ], resolve),
  },
  // 南溪阿姐-活动管理
  {
    path: "futureService/sisterActivityList",
    name: "sisterActivityList",
    meta: {
      title: "活动管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/sisterActivityList.vue",
      ], resolve),
  },
  {
    path: "futureService/addActivitySister",
    name: "addActivitySister",
    meta: {
      echo: "futureService/sisterActivityList",
      title: "活动编辑",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/addActivitySister.vue",
      ], resolve),
  },
  {
    path: "futureService/activityApplicantsSister",
    name: "activityApplicantsSister",
    meta: {
      echo: "futureService/sisterActivityList",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/activityApplicantsSister.vue",
      ], resolve),
  },
  {
    path: "futureService/activityPointFormSister",
    name: "activityPointFormSister",
    meta: {
      echo: "futureService/sisterActivityList",
      title: "编辑积分",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/activityPointFormSister.vue",
      ], resolve),
  },
  {
    path: "futureService/activityFeedbackSister",
    name: "activityFeedbackSister",
    meta: {
      echo: "futureService/sisterActivityList",
      title: "活动反馈",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/southStreamSister/activityFeedbackSister.vue",
      ], resolve),
  },
  // 青少年关爱-课程管理
  {
    path: "futureService/tennagersActivityList",
    name: "tennagersActivityList",
    meta: {
      title: "课程管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/tennagersActivityList.vue",
      ], resolve),
  },
  {
    path: "futureService/addActivityTennagers",
    name: "addActivityTennagers",
    meta: {
      echo: "futureService/tennagersActivityList",
      title: "课程编辑",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/addActivityTennagers.vue",
      ], resolve),
  },
  {
    path: "futureService/activityApplicantsTennagers",
    name: "activityApplicantsTennagers",
    meta: {
      echo: "futureService/tennagersActivityList",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/activityApplicantsTennagers.vue",
      ], resolve),
  },
  {
    path: "futureService/activityFeedbackTennagers",
    name: "activityFeedbackTennagers",
    meta: {
      echo: "futureService/tennagersActivityList",
      title: "活动回顾",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/activityFeedbackTennagers.vue",
      ], resolve),
  },
  {
    path: "futureService/activityPointFormTennagers",
    name: "activityPointFormTennagers",
    meta: {
      echo: "futureService/tennagersActivityList",
      title: "编辑积分",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/activityPointFormTennagers.vue",
      ], resolve),
  },
  // 青少年关爱-心理评估
  {
    path: "futureService/tennagersMindList",
    name: "tennagersMindList",
    meta: {
      title: "心理评估",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/tennagersMindList.vue",
      ], resolve),
  },
  // 青少年关爱-心理评估-问卷得分列表
  {
    path: "futureService/tebbagersScoreList",
    name: "tebbagersScoreList",
    meta: {
      echo: "futureService/tennagersMindList",
      title: "问卷得分",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/tebbagersScoreList.vue",
      ], resolve),
  },
  // 青少年关爱-心理评估-编辑新增
  {
    path: "futureService/tennagersMindForm",
    name: "tennagersMindForm",
    meta: {
      echo: "futureService/tennagersMindList",
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/tennagersMindForm.vue",
      ], resolve),
  },
  // 青少年关爱-文章管理
  {
    path: "futureService/tennagersArticleList",
    name: "tennagersArticleList",
    meta: {
      title: "文章管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/tennagersArticleList.vue",
      ], resolve),
  },
  // 青少年关爱-文章管理-编辑新增
  {
    path: "futureService/tennagersArticleForm",
    name: "tennagersArticleForm",
    meta: {
      echo: "futureService/tennagersArticleList",
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/careForTeenagers/tennagersArticleForm.vue",
      ], resolve),
  },
  /** 新商城 START */
  // 新商城-公告发布列表
  {
    path: "futureService/businessNewTopicList",
    name: "businessNewTopicList",
    meta: {
      title: "公告发布列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/topic/topicList.vue",
      ], resolve),
  },
  // 新商城-公告发布表单
  {
    path: "futureService/businessNewTopicForm",
    name: "businessNewTopicForm",
    meta: {
      echo: "futureService/businessNewTopicList",
      title: "公告发布表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/topic/topicForm.vue",
      ], resolve),
  },
  // 新商城-商品发布列表
  {
    path: "futureService/businessNewGoodsList",
    name: "businessNewGoodsList",
    meta: {
      title: "商品发布列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewGoodsList.vue",
      ], resolve),
  },
  // 新商城-商品发布表单
  {
    path: "futureService/businessNewGoodsForm",
    name: "businessNewGoodsForm",
    meta: {
      echo: "futureService/businessNewGoodsList",
      title: "商品发布表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewGoodsForm.vue",
      ], resolve),
  },
  // 新商城-团长审核列表页
  {
    path: "futureService/audioGroupHeaderList",
    name: "audioGroupHeaderList",
    meta: {
      title: "团长审核列表页",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/audioGroupHeaderList.vue",
      ], resolve),
  },
  // 新商城-团长审核表单页
  {
    path: "futureService/audioGroupHeaderForm",
    name: "audioGroupHeaderForm",
    meta: {
      echo: "futureService/audioGroupHeaderList",
      title: "团长审核表单页",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/audioGroupHeaderForm.vue",
      ], resolve),
  },
  // 新商城-团购/秒杀列表页
  {
    path: "futureService/businessNewGroupList",
    name: "businessNewGroupList",
    meta: {
      title: "团购/秒杀列表页",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewGroupList.vue",
      ], resolve),
  },
  // 新商城-团购/秒杀订单列表页
  {
    path: "futureService/businessNewGroupOrder",
    name: "businessNewGroupOrder",
    meta: {
      echo: "futureService/businessNewGroupList",
      title: "团购/秒杀订单列表页",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewGroupOrder.vue",
      ], resolve),
  },
  // 新商城-团购/秒杀表单页
  {
    path: "futureService/businessNewGroupForm",
    name: "businessNewGroupForm",
    meta: {
      echo: "futureService/businessNewGroupList",
      title: "团购/秒杀表单页",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewGroupForm.vue",
      ], resolve),
  },
  // 新商城-一刻钟商圈-一刻钟商家列表
  {
    path: "futureService/quarterBusinessList",
    name: "quarterBusinessList",
    meta: {
      title: "线上店铺商家列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/quarter/quarterBusinessList.vue",
      ], resolve),
  },
  // 新商城-一刻钟商圈-一刻钟商家表单
  {
    path: "futureService/quarterBusinessForm",
    name: "quarterBusinessForm",
    meta: {
      echo: "futureService/quarterBusinessList",
      title: "线上店铺商家表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/quarter/quarterBusinessForm.vue",
      ], resolve),
  },
  // 新商城-一刻钟商圈-一刻钟商品列表
  {
    path: "futureService/quarterGoodsList",
    name: "quarterGoodsList",
    meta: {
      title: "线上店铺商品列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/quarter/quarterGoodsList.vue",
      ], resolve),
  },
  // 新商城-一刻钟商圈-一刻钟商品表单
  {
    path: "futureService/quarterGoodsForm",
    name: "quarterGoodsForm",
    meta: {
      echo: "futureService/quarterGoodsList",
      title: "线上店铺商品表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/quarter/quarterGoodsForm.vue",
      ], resolve),
  },
  // 新商城-一刻钟商圈-一刻钟订单列表
  {
    path: "futureService/quarterOrderList",
    name: "quarterOrderList",
    meta: {
      title: "线上店铺订单列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/quarter/quarterOrderList.vue",
      ], resolve),
  },
  // 新商城-订单列表
  {
    path: "futureService/businessNewOrder",
    name: "businessNewOrder",
    meta: {
      title: "订单列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewOrder.vue",
      ], resolve),
  },
  // 新商城-订单详情
  {
    path: "futureService/businessNewOrderDetail",
    name: "businessNewOrderDetail",
    meta: {
      title: "订单详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessNew/businessNewOrderDetail.vue",
      ], resolve),
  },
  /* 新商城 END */

  // 便民服务
  {
    path: "futureService/convenientService",
    name: "convenientService",
    meta: {
      title: "便民服务",
    },
    component: (resolve) =>
      require(["@/views/futureService/convenientService/index.vue"], resolve),
  },
  // 便民服务-编辑or新增
  {
    path: "futureService/convenientServiceEdit",
    name: "convenientServiceEdit",
    meta: {
      title: "便民服务",
    },
    component: (resolve) =>
      require(["@/views/futureService/convenientService/edit.vue"], resolve),
  },
];

export default routes;
