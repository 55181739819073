<template>
  <div class="v-form">
    <el-row :gutter="gutter" v-if="isEdit">
      <el-form
        class="edit"
        ref="form"
        labelPosition="top"
        inline
        :model="form"
        :rules="rules"
      >
        <el-col
          v-for="(field, index) in fields"
          :key="`form-item-${field.fieldName}-${index}`"
          :span="6"
        >
          <el-form-item
            :rules="[
              {
                required: field.required,
                message: `请输入${field.fieldLabel}`,
                trigger: 'blur',
              },
            ]"
            v-if="field.fieldType === 'input' || field.fieldType === 'number'"
            :label="field.fieldLabel"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
              <!-- <span>
                <el-tag
                  @click="
                    handelClickTag({
                      label: field.fieldLabel,
                      prop: field.fieldName,
                    })
                  "
                  size="medium"
                  class="vTag"
                  >改动日志</el-tag
                ></span
              > -->
            </template>
            <v-input
              :type="field.fieldType"
              :maxlength="field.maxLength"
              placeholder="请输入"
              v-model="form[field.fieldName]"
            ></v-input>
          </el-form-item>
          <el-form-item
            :rules="[
              {
                required: field.required,
                message: `请输入${field.fieldLabel}`,
                trigger: 'blur',
              },
            ]"
            v-if="field.fieldType === 'decimal'"
            :label="field.fieldLabel"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
              <!-- <span>
                <el-tag
                  @click="
                    handelClickTag({
                      label: field.fieldLabel,
                      prop: field.fieldName,
                    })
                  "
                  size="medium"
                  class="vTag"
                  >改动日志</el-tag
                ></span
              > -->
            </template>
            <v-input-number
              :precision="2"
              v-model="form[field.fieldName]"
            ></v-input-number>
          </el-form-item>
          <el-form-item
            v-if="field.fieldType === 'select'"
            :label="field.fieldLabel"
            :rules="[
              {
                required: field.required,
                message: `请选择${field.fieldLabel}`,
                trigger: 'blur',
              },
            ]"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
              <!-- <span>
                <el-tag
                  size="medium"
                  class="vTag"
                  @click="
                    handelClickTag({
                      label: field.fieldLabel,
                      prop: field.fieldName,
                    })
                  "
                  >改动日志</el-tag
                ></span
              > -->
            </template>
            <v-select
              :multiple="field.multiple"
              collapseTags
              :options="field.options"
              v-model="form[field.fieldName]"
              placeholder="请选择"
            ></v-select>
          </el-form-item>
          <el-form-item
            v-if="field.fieldType === 'date'"
            :rules="[
              {
                required: field.required,
                message: `请选择`,
                trigger: 'blur',
              },
            ]"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
              <!-- <span>
                <el-tag
                  size="medium"
                  class="vTag"
                  @click="
                    handelClickTag({
                      label: field.fieldLabel,
                      prop: field.fieldName,
                    })
                  "
                  >改动日志</el-tag
                ></span
              > -->
            </template>
            <v-date-picker
              type="date"
              v-model="form[field.fieldName]"
              formatValue="yyyy-MM-dd"
            ></v-date-picker>
          </el-form-item>
          <el-form-item
            v-if="field.fieldType === 'datetime'"
            :rules="[
              {
                required: field.required,
                message: `请选择`,
                trigger: 'blur',
              },
            ]"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
              <!-- <span>
                <el-tag
                  size="medium"
                  class="vTag"
                  @click="
                    handelClickTag({
                      label: field.fieldLabel,
                      prop: field.fieldName,
                    })
                  "
                  >改动日志</el-tag
                ></span
              > -->
            </template>
            <v-date-picker
              type="datetime"
              v-model="form[field.fieldName]"
              formatValue="yyyy-MM-dd HH:mm:ss"
            ></v-date-picker>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row v-else>
      <el-form inline :model="form" :rules="rules" class="view-form">
        <el-col
          v-for="(field, index) in fields"
          :key="`form-item-${field.fieldName}-${index}`"
          :span="12"
        >
          <el-form-item
            v-if="field.fieldType === 'input' || field.fieldType === 'number'"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}：</span>
            </template>

            <v-input
              disabled
              :type="field.fieldType"
              placeholder="请选择"
              v-model="form[field.fieldName]"
            ></v-input>
            <!-- <span>
              <el-tag
                @click="
                  handelClickTag({
                    label: field.fieldLabel,
                    prop: field.fieldName,
                  })
                "
                size="medium"
                class="vTag"
                >改动日志</el-tag
              ></span
            > -->
          </el-form-item>
          <el-form-item
            v-if="field.fieldType === 'decimal'"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}：</span>
            </template>
            <v-input-number
              disabled
              :precision="2"
              v-model="form[field.fieldName]"
            ></v-input-number>

            <!-- <span>
              <el-tag
                @click="
                  handelClickTag({
                    label: field.fieldLabel,
                    prop: field.fieldName,
                  })
                "
                size="medium"
                class="vTag"
                >改动日志</el-tag
              ></span
            > -->
          </el-form-item>

          <el-form-item
            v-if="field.fieldType === 'select'"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}：</span>
            </template>
            <v-select
              disabled
              :multiple="field.multiple"
              collapseTags
              :options="field.options"
              v-model="form[field.fieldName]"
              placeholder="请选择"
            ></v-select>
            <!-- <span>
              <el-tag
                size="medium"
                class="vTag"
                @click="
                  handelClickTag({ label: field.label, prop: field.prop })
                "
                >改动日志</el-tag
              ></span
            > -->
          </el-form-item>
          <el-form-item
            v-if="field.fieldType === 'date'"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
            </template>
            <v-date-picker
              disabled
              type="date"
              v-model="form[field.fieldName]"
              formatValue="yyyy-MM-dd"
            ></v-date-picker>
            <!-- <span>
              <el-tag
                size="medium"
                class="vTag"
                @click="
                  handelClickTag({
                    label: field.fieldLabel,
                    prop: field.fieldName,
                  })
                "
                >改动日志</el-tag
              ></span
            > -->
          </el-form-item>
          <el-form-item
            v-if="field.fieldType === 'datetime'"
            :prop="field.fieldName"
          >
            <template #label>
              <span class="form-item-width">{{ field.fieldLabel }}</span>
            </template>
            <v-date-picker
              disabled
              type="datetime"
              v-model="form[field.fieldName]"
              formatValue="yyyy-MM-dd HH:mm:ss"
            ></v-date-picker>
            <!-- <span>
              <el-tag
                size="medium"
                class="vTag"
                @click="
                  handelClickTag({
                    label: field.fieldLabel,
                    prop: field.fieldName,
                  })
                "
                >改动日志</el-tag
              ></span
            > -->
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <!-- <div class="view" v-else>
      <div class="form-control" v-for="field in fields" :key="field.fieldName">
        <div
          class="form-component"
          v-if="field.fieldType === 'input' || field.fieldType === 'number'"
        >
          <span>{{ field.fieldLabel }}：</span>
          <span>{{ form[field.fieldName] }}</span>
        </div>
        <div class="form-component" v-if="field.fieldType === 'select'">
          <span>{{ field.fieldLabel }}：</span>
          <span>{{ form[field.fieldName] }}</span>
        </div>
        <div class="form-component" v-if="field.fieldType === 'date'">
          <span>{{ field.fieldLabel }}：</span>
          <span>{{ form[field.fieldName] }}</span>
        </div>
        <div class="form-component" v-if="field.fieldType === 'decimal'">
          <span>{{ field.fieldLabel }}：</span>
          <span>{{ form[field.fieldName] }}</span>
        </div>
        <div class="form-component" v-if="field.fieldType === 'datetime'">
          <span>{{ field.fieldLabel }}：</span>
          <span>{{ form[field.fieldName] }}</span>
        </div>
      </div>
    </div> -->
    <div class="my-drawer-content" v-if="isEdit">
      <!-- 保存和上报按钮 -->
      <div class="my-drawer-buttons">
        <v-button text="保存" @click="save"></v-button>
        <v-button
          text="上报"
          v-if="isShowReportBtn"
          @click="report"
          type="primary"
        ></v-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Col, Row } from "element-ui";
Vue.use(Col);
Vue.use(Row);
import vDatePicker from "../v-date-picker";
export default {
  name: "v-form",
  components: { vDatePicker },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    dataList: {
      type: Object,
      default: () => {},
    },
    isShowReportBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {},
      rules: {},
      gutter: 20,
      options: [
        {
          label: "测试",
          value: 0,
        },
      ],
      span: 12,
    };
  },
  created() {},
  watch: {
    fields: {
      handler(v) {
        this.initForm();
      },
      immediate: true,
    },
    dataList: {
      handler(v) {
        this.filterList();
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    filterList() {
      Object.keys(this.form).forEach((key) => {
        if (Object.keys(this.dataList).length > 0) {
          if (this.isEdit) {
            this.form[key] = [undefined, ""].includes(this.dataList[key])
              ? null
              : this.dataList[key];
          } else {
            this.form[key] = [undefined, null, ""].includes(this.dataList[key])
              ? "--"
              : this.dataList[key];
          }
        } else {
          this.form[key] = null;
        }
      });
    },
    //点击改动日志
    handelClickTag(arg) {
      this.$emit("handelClickTag", arg);
    },
    //初始化数据
    initForm() {
      this.fields.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(this.form, field.fieldName)) {
          this.form[field.fieldName] = null;
        } else {
          this.$set(this.form, field.fieldName, null);
        }
      });
    },
    //保存
    save() {
      this.$refs.form.validate((result, object) => {
        if (result) {
          this.$emit("save", this.form);
        }
      });
    },
    //上报
    report() {
      this.$refs.form.validate((result, object) => {
        if (result) {
          this.$emit("report", this.form);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.v-form {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  ::v-deep .el-row {
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;

    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      background-color: #fff;
    }

    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
    }
  }
  .view-form {
    .el-col {
      border: 1px solid #f0f0f0;
    }
    ::v-deep .el-form-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    ::v-deep .el-form-item__label {
      width: 40%;
      height: 20px;
      line-height: 20px;
      .form-item-width {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    ::v-deep .el-form-item__content {
      width: 60%;
      display: flex;
      .vTag {
        cursor: pointer;
      }
      .v-input {
        width: 100%;
      }
      .el-input {
        width: 100% !important;
        input {
          background-color: transparent;
          border: none;
          color: #606266;
        }
        .el-input__suffix {
          display: none;
        }
      }
      .v-input-number-container {
        width: 100% !important;
        .el-input-number {
          width: 100% !important;
        }
      }
      .v-select {
        width: 100%;
        .el-select {
          width: 100% !important;
        }
      }

      .v-date-picker {
        width: 100%;
      }
    }
  }
  .view {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    .form-control {
      box-sizing: border-box;
      width: 50%;
      display: flex;
      outline: 1px solid #eee;
      .form-component {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px 20px;
        span {
          &:first-child {
            width: 20%;
          }
          &:last-child {
            width: 80%;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .edit {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__label {
        display: flex;
        height: 40px;
        line-height: 40px;

        .form-item-width {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          width: 100%;
        }
        .vTag {
          cursor: pointer;
        }
      }
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100% !important;
        }
        .v-input-number-container {
          width: 100% !important;
          .el-input-number {
            width: 100% !important;
          }
        }
        .el-select {
          width: 100% !important;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }

  .my-drawer-content {
    position: absolute;
    right: 30px;
    bottom: 30px;
    .my-drawer-buttons {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }
  }
}
</style>
