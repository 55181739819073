const routes = [
  {
    path: "futureCreateWork/createWorkTalent",
    name: "createWorkTalent",
    meta: {
      title: "创业达人",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTalent/index.vue"], resolve),
  },
  {
    path: "futureCreateWork/createWorkTalentForm",
    name: "createWorkTalentForm",
    meta: {
      echo: "futureCreateWork/createWorkTalent",
      title: "创业达人",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTalent/createWorkTalentForm.vue",
      ], resolve),
  },
  //创业导师
  {
    path: "futureCreateWork/createWorkTeacher",
    name: "createWorkTeacher",
    meta: {
      title: "创业导师",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTeacher/index"], resolve),
  },
  //新增创业导师
  {
    path: "futureCreateWork/createWorkTeacherForm",
    name: "createWorkTeacherForm",
    meta: {
      title: "创业导师",
      echo: "futureCreateWork/createWorkTeacher",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTeacher/createWorkTeacherForm",
      ], resolve),
  },

  //服务联盟
  {
    path: "futureCreateWork/serviceLeague",
    name: "serviceLeague",
    meta: {
      title: "服务联盟",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/serviceLeague/index"], resolve),
  },
  //新增服务联盟
  {
    path: "futureCreateWork/serviceLeagueForm",
    name: "serviceLeagueForm",
    meta: {
      title: "新增服务联盟",
      echo: "futureCreateWork/serviceLeague",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/serviceLeague/serviceLeagueForm",
      ], resolve),
  },
  //租赁场所
  {
    path: "futureCreateWork/rentPlace",
    name: "rentPlace",
    meta: {
      title: "租赁场所",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/index"], resolve),
  },
  //租赁场所详情
  {
    path: "futureCreateWork/rentPlaceDetail",
    name: "rentPlaceDetail",
    meta: {
      title: "租赁场所详情",
      echo: "futureCreateWork/rentPlace",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceDetail"], resolve),
  },
  //租赁订单
  {
    path: "futureCreateWork/rentPlaceOrder",
    name: "rentPlaceOrder",
    meta: {
      title: "租赁订单",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceOrder"], resolve),
  },
  //租赁订单详情
  {
    path: "futureCreateWork/rentPlaceOrderForm",
    name: "rentPlaceOrderForm",
    meta: {
      title: "租赁订单详情",
      echo: "futureCreateWork/rentPlaceOrder",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/rentPlace/rentPlaceOrderForm",
      ], resolve),
  },
  //楼宇管理
  {
    path: "futureCreateWork/buildingManagement",
    name: "buildingManagement",
    meta: {
      title: "楼宇管理",
      echo: "futureCreateWork/buildingManagement",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/index.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/businessPage",
    name: "businessPage",
    meta: {
      title: "营业收入",
      echo: "futureCreateWork/businessPage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/businessPage.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/taxSourcePage",
    name: "taxSourcePage",
    meta: {
      title: "总税收",
      echo: "futureCreateWork/taxSourcePage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/taxSourcePage.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/visitorsPage",
    name: "visitorsPage",
    meta: {
      title: "走访记录",
      echo: "futureCreateWork/visitorsPage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/visitorsPage.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/visitors",
    name: "visitors",
    meta: {
      title: "走访人员",
      echo: "futureCreateWork/visitors",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/visitors.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/importBuilding",
    name: "importBuilding",
    meta: {
      title: "导入信息",
      echo: "futureCreateWork/importBuilding",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/importBuilding.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/buildingManagementForm",
    name: "buildingManagementForm",
    meta: {
      title: "编辑",
      echo: "futureCreateWork/buildingManagementForm",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/buildingManagementForm.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/fenghuaPartyInfo",
    name: "fenghuaPartyInfo",
    meta: {
      title: "奉化党建",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/fenghuaPartyInfo/index.vue"], resolve),
  },
  {
    path: "futureCreateWork/fenghuaPartyInfoForm",
    name: "fenghuaPartyInfoForm",
    meta: {
      title: "奉化党建",
      echo: "futureCreateWork/fenghuaPartyInfo",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/fenghuaPartyInfo/partyInfoForm.vue",
      ], resolve),
  },
  // 海定十法-党务发布
  {
    path: "futureCreateWork/partyAffairsRelease",
    name: "partyAffairsRelease",
    meta: {
      title: "党务发布",
      echo: "futureCreateWork/partyAffairsRelease",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/partyAffairsRelease.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/partyAffairsReleaseForm",
    name: "partyAffairsReleaseForm",
    meta: {
      title: "党务发布",
      echo: "futureCreateWork/partyAffairsReleaseForm",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/partyAffairsReleaseForm.vue",
      ], resolve),
  },
  // 海定十法-队伍管理
  {
    path: "futureCreateWork/teamManagement",
    name: "teamManagement",
    meta: {
      title: "队伍管理",
      echo: "futureCreateWork/teamManagement",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/teamManagement.vue",
      ], resolve),
  },
  // 海定十法-一事一议一签-提议审核
  {
    path: "futureCreateWork/proposalExamine",
    name: "proposalExamine",
    meta: {
      title: "提议审核",
      echo: "futureCreateWork/proposalExamine",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/proposalExamine.vue",
      ], resolve),
  },
  // 海定十法-一事一议一签-提议审议
  {
    path: "futureCreateWork/proposalConsideration",
    name: "proposalConsideration",
    meta: {
      title: "提议审议",
      echo: "futureCreateWork/proposalConsideration",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/proposalConsideration.vue",
      ], resolve),
  },
  // 海定十法-一事一议一签-提议商议
  {
    path: "futureCreateWork/proposalNegotiate",
    name: "proposalNegotiate",
    meta: {
      title: "提议商议",
      echo: "futureCreateWork/proposalNegotiate",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/proposalNegotiate.vue",
      ], resolve),
  },
  // 海定十法-一事一议一签-提议决议
  {
    path: "futureCreateWork/proposalResolution",
    name: "proposalResolution",
    meta: {
      title: "提议审核",
      echo: "futureCreateWork/proposalResolution",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/proposalResolution.vue",
      ], resolve),
  },
  // 海定十法-一事一议一签-提议公示
  {
    path: "futureCreateWork/proposalPublicity",
    name: "proposalPublicity",
    meta: {
      title: "提议审核",
      echo: "futureCreateWork/proposalPublicity",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/proposalPublicity.vue",
      ], resolve),
  },
  //金钟农贸
  {
    path: "futureCreateWork/jinzhongAgriculturalTrade",
    name: "jinzhongAgriculturalTrade",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/jinzhongAgriculturalTrade/index.vue",
      ], resolve),
  },
];

export default routes;
