var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:['v-ueditor', _vm.isDisable ? 'v-ueditor-disabled' : '']},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"myQuillEditor ql-editor",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.onChange($event)},"focus":function($event){return _vm.quillEditorFocus($event)}},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('form',{attrs:{"action":"","method":"post","enctype":"multipart/form-data","id":"uploadFormMulti"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"id":_vm.uniqueId,"type":"file","name":"file","multiple":"","accept":"image/jpg, image/jpeg, image/png, image/gif"},on:{"change":function($event){return _vm.uploadImg('uploadFormMulti')}}})]),_c('v-dialog',{staticClass:"editerV",attrs:{"title":"视频上传","close-on-click-modal":false,"appendToBody":true},on:{"confirm":_vm.confirm},model:{value:(_vm.videoFlag),callback:function ($$v) {_vm.videoFlag=$$v},expression:"videoFlag"}},[_c('div',{staticClass:"editerVideo"},[_c('div',{staticClass:"editerVideo_title"},[_c('div',{class:[
            'editerVideo_title_item',
            _vm.videoForm.tabUrl ? 'editerVideo_title_act' : '',
          ],on:{"click":function($event){return _vm.onVideoTab(1)}}},[_vm._v(" 添加链接 ")]),_c('div',{class:[
            'editerVideo_title_item',
            !_vm.videoForm.tabUrl ? 'editerVideo_title_act' : '',
          ],on:{"click":function($event){return _vm.onVideoTab(2)}}},[_vm._v(" 上传视频 ")])]),_c('div',{staticClass:"editerVideo_main"},[(_vm.videoForm.tabUrl)?_c('div',{staticClass:"editerVideo_main_url"},[_c('div',[_vm._v("视频地址：")]),_c('v-input',{staticStyle:{"width":"300px"},attrs:{"size":"small"},model:{value:(_vm.videoForm.videoUrl),callback:function ($$v) {_vm.$set(_vm.videoForm, "videoUrl", $$v)},expression:"videoForm.videoUrl"}})],1):_vm._e(),(!_vm.videoForm.tabUrl)?_c('div',[_c('v-videoUpload',{attrs:{"limit":1,"videoUrls":_vm.videoForm.showVideoPath},on:{"update:videoUrls":function($event){return _vm.$set(_vm.videoForm, "showVideoPath", $event)},"update:video-urls":function($event){return _vm.$set(_vm.videoForm, "showVideoPath", $event)}}})],1):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }