<template>
  <div class="hc-list">
    <div class="headBtn" v-if="isHead">
      <template>
        <v-button
          text="导出"
          v-if="
            Object.keys(exportParams) && Object.keys(exportParams).length != 0
          "
          @click="exportData"
        ></v-button>
        <slot name="headBtnSlot"></slot>
      </template>
    </div>
    <div class="search">
      <div class="search-data">
        <template>
          <slot name="searchSlot"></slot>
        </template>
      </div>
      <div class="search-btn" v-if="isSearch">
        <v-button text="搜索" @click="search"></v-button>
      </div>
    </div>
    <div class="oTable">
      <v-table
        :tableData="tableData"
        ref="vTable"
        :isIndex="isIndex"
        :headers="headers"
        :isManySelect="isManySelect"
        :isOperateColumn="isOperateColumn"
        :isLoading="isLoading"
        :selectable="selectable"
        :cellStyle="cellStyle"
        :headerCellStyle="headerCellStyle"
        @selection-change="handleSelectionChange"
      >
        <template #operateSlot="scope">
          <div class="opera-btn">
            <!-- 配置表格中的操作按钮 -->
            <slot name="operateSlot" :row="scope.row"></slot>
          </div>
        </template>
      </v-table>
    </div>
    <div class="footer">
      <div class="button-group">
        <div class="batch">
          <!-- 配置左下角批量操作按钮 -->
          <slot name="batchSlot" :selectedData="selectedData"></slot>
        </div>
      </div>
      <v-pagination
        :total="pages.total"
        :curPage="pages.curPage"
        :pageSize="pages.pageSize"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { downloadHelper } from "@/utils/func";
import { Table, TableColumn, Pagination } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
export default {
  name: "hc-list",
  data() {
    return {
      exportParamsValue: this.exportParams,
      tableData: [],
      isLoading: false,
      currentPage4: 1,
      options: [],
      selectedData: {
        data: [],
        ids: [],
      },
      pages: {
        total: 0,
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  props: {
    methods: { type: String, default: "get" },
    tableUrl: {
      type: String,
      default: "",
    },
    // 是否显示序号
    isIndex: {
      type: Boolean,
      default: false,
    },
    // 是否显示选择列
    isManySelect: {
      type: Boolean,
      default: false,
    },
    //列表请求数据
    listData: {
      type: Object,
    },
    // 是否显示操作列
    isOperateColumn: {
      type: Boolean,
      default: true,
    },
    // 是否显示顶部
    isHead: {
      type: Boolean,
      default: true,
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
    //仅对 type=selection 的列有效，决定改行 CheckBox 是否可以勾选
    selectable: {
      type: Function,
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    beforeList: {
      type: Function,
    },
    afterList: {
      type: Function,
    },
    afterReturnList: {
      type: Function,
    },
    exportParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    postData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    searchParam: {
      type: Object,
      default: function () {
        return {};
      },
    },
    extraParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    cellStyle: [Function, Object],
    headerCellStyle: [Function, Object],
  },
  async mounted() {
    this.beforeList ? await this.beforeList() : "";
    await this.getList();
  },
  watch: {
    obj: {
      exportParams(newName, oldName) {
        this.exportParamsValue = newName;
      },
      deep: true,
    },
  },
  methods: {
    toggleSelection() {
      this.$refs.vTable.toggleSelection();
    },
    exportData() {
      let obj = {};
      Object.assign(obj, this.exportParamsValue.params);
      if (obj.curPage) {
        delete obj.curPage;
      }
      if (obj.pageSize) {
        delete obj.pageSize;
      }
      let url = this.exportParamsValue.url;
      if (this.exportParamsValue.methods == "post") {
        this.$axios.post(`${url}`, this.$qs.stringify(obj)).then((res) => {
          if (res.code === 200) {
            downloadHelper.downloadByLocation(res.data.tempFileUrl);
          } else {
          }
        });
      } else {
        this.$axios.get(`${url}`, { params: obj }).then((res) => {
          if (res.code === 200) {
            downloadHelper.downloadByLocation(res.data.tempFileUrl);
          } else {
          }
        });
      }
    },
    search() {
      this.pages = {
        total: 0,
        curPage: 1,
        pageSize: 10,
      };
      this.toggleSelection();
      this.getList();
    },
    handleSelectionChange(val) {
      console.log(val, "----0");
      this.selectedData.data = val;
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      this.selectedData.ids = ids;
      this.$emit("selection-change", val);
    },
    handleSizeChange(val) {
      this.pages.curPage = 1;
      this.pages.pageSize = val;
      this.getList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pages.curPage = val;
      this.getList();
      console.log(`当前页: ${val}`);
    },
    async getList() {
      this.tableData = [];
      let params = Object.assign(this.searchParam, this.extraParams, {
        curPage: this.pages.curPage,
        pageSize: this.pages.pageSize,
      });
      this.isLoading = true;
      let oData = Object.assign(this.postData, {});
      if (this.pages.curPage && this.pages.pageSize) {
        oData = Object.assign(oData, {
          curPage: this.pages.curPage,
          pageSize: this.pages.pageSize,
        });
      }

      let res = await this.$axios({
        url: `${this.tableUrl}`,
        method: this.methods,
        params: params,
        data: oData,
      });
      this.isLoading = false;
      if (res.code === 200) {
        if (res.data.houseList) {
          this.pages.total = res.data.houseList.total || 0;
        } else {
          this.pages.total = res.data.total || 0;
        }
        // this.pages.total = res.data.total || res.data.houseList.total || 0;
        // if (res.data.records.length === 0) {
        //   return;
        // }
        // this.tableData = res.data.records || res.data.houseList.records || [];/
        const data = res.data.records || res.data.houseList.records || [];
        this.tableData = this.afterReturnList ? await this.afterReturnList(data) : data;
        this.afterList ? await this.afterList(res.data) : "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.hc-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  ::v-deep .v-control {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 5px 20px 5px 0;
    margin-right: 20px;
  }
  .headBtn {
    display: flex;
    justify-content: flex-end;
    // position: absolute;
    // top: 10px;
    // right: 20px;
    height: 50px;
    min-height: 50px;
    flex-wrap: wrap;
  }
  .search {
    display: flex;
    justify-content: space-between;
    .search-data {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .search-btn {
      white-space: nowrap;
    }
  }
  .oTable {
    flex: 1;
    margin-top: 10px;
    overflow-x: auto;
    // // overflow-y: hidden;
    min-height: auto;
    // padding-bottom: 44px;
    // overflow: scroll;
    // overflow: auto;
    // height: 100%;
  }
  // .footer {
  //   // margin-bottom: 40px;
  //   padding: 10px 0;
  //   text-align: right;
  // }
  .footer {
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    margin: 10px 0;
    .button-group {
      display: flex;
      .batch {
        display: flex;
        > :nth-child(n) {
          margin-right: 12px;
        }
        > :last-child {
          padding-right: 12px;
          // border-right: 1px solid #1b8cf2;
        }
      }
    }
    ::v-deep .v-pagination {
      display: flex;
      align-items: center;
    }
  }
}
</style>
<style lang="less">
// .hc-list {
//   .v-control {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     margin-bottom: 20px;
//     margin-right: 20px;
//   }
// }
</style>
