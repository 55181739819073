const routes = [
  //物联网产品
  //产品管理
  {
    path: "iotInternet/iotProduct",
    name: "iotProduct",
    meta: {
      title: "产品管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotProduct/index.vue"], resolve),
  },
  {
    path: "iotInternet/iotProductForm",
    name: "iotProductForm",
    meta: {
      echo: "iotInternet/iotProduct",
      title: "产品管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotProduct/iotProductForm.vue"], resolve),
  },
  {
    path: "iotInternet/iotProductImportForm",
    name: "iotProductImportForm",
    meta: {
      echo: "iotInternet/iotProduct",
      title: "产品导入模板",
    },
    component: (resolve) =>
      require([
        "@/views/iotInternet/iotProduct/iotProductImportForm.vue",
      ], resolve),
  },
  //设备管理
  {
    path: "iotInternet/iotDevice",
    name: "iotDevice",
    meta: {
      title: "设备管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotDevice/index.vue"], resolve),
  },
  {
    path: "iotInternet/iotDeviceForm",
    name: "iotDeviceForm",
    meta: {
      echo: "iotInternet/iotDevice",
      title: "设备管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotDevice/iotDeviceForm.vue"], resolve),
  },
  {
    path: "iotInternet/iotDeviceDetail",
    name: "iotDeviceDetail",
    meta: {
      echo: "iotInternet/iotDevice",
      title: "设备管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotDevice/iotDeviceDetail.vue"], resolve),
  },
  {
    path: "iotInternet/iotDeviceImportForm",
    name: "iotDeviceImportForm",
    meta: {
      echo: "iotInternet/iotDevice",
      title: "设备导入模板",
    },
    component: (resolve) =>
      require([
        "@/views/iotInternet/iotDevice/iotDeviceImportForm.vue",
      ], resolve),
  },
  //告警中心
  {
    path: "iotInternet/iotWarning",
    name: "iotWarning",
    meta: {
      title: "告警中心",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotWarning/index.vue"], resolve),
  },
  //总览
  {
    path: "iotInternet/overView",
    name: "overView",
    meta: {
      title: "总览",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/overView/index.vue"], resolve),
  },
  //规则管理
  {
    path: "iotInternet/iotRule",
    name: "iotRule",
    meta: {
      title: "规则管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotRule/index.vue"], resolve),
  },
  {
    path: "iotInternet/iotRuleForm",
    name: "iotRuleForm",
    meta: {
      title: "规则管理",
    },
    component: (resolve) =>
      require(["@/views/iotInternet/iotRule/iotRuleForm.vue"], resolve),
  },
];

export default routes;
