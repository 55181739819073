<template>
  <div class="v-upload">
    <!-- 
      class
        - hide        隐藏上传按钮
      action          必选参数，上传的地址
      list-type       文件列表的类型, 此处仅考虑图片
      disabled        是否禁用
      limit           最大允许上传个数
      file-list       上传的文件列表
      before-upload   上传文件之前
      on-change       文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
      on-error        文件上传失败时的钩子
      on-preview      点击文件列表中已上传的文件时的钩子
      on-remove       文件列表移除文件时的钩子
      on-success      文件上传成功时的钩子
      on-exceed       文件超出个数限制时的钩子 function(files, fileList)
     -->
    <div
      class="pic-list"
      v-for="(pic, index) in videoUrls ? videoUrls.split(',') : []"
      :key="`${index}_${pic}`"
    >
      <video
        class="upload-list__item-thumbnail"
        :style="{
          'object-fit': fit,
        }"
        :src="pic"
        controls="controls"
      ></video>
      <!-- <a class="upload-list__item-name">
          <i class="icon-document"></i>
        </a> -->
      <label class="upload-list__item-status-label">
        <i class="el-icon-upload-success el-icon-check"></i>
      </label>
      <!-- <i class="icon-close"> </i> -->
      <span class="upload-list__item-actions">
        <span
          v-show="!disabled"
          class="upload-list__item-delete"
          @click="handleRemove(pic)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
    <div
      class="pic-list"
      v-for="(item, index) in loadList"
      :key="`load_${index}`"
      :loading="+item.percentage === 100"
    >
      <el-progress
        class="progress"
        type="circle"
        :percentage="item.percentage ? Number(item.percentage.toFixed(0)) : 0"
      ></el-progress>
    </div>
    <el-upload
      v-loading="isLoading"
      class="pic-upload"
      :multiple="true"
      :class="{ hide: hideUpload || disabled }"
      :action="uploadImgUrl"
      list-type="picture-card"
      :disabled="disabled"
      :limit="limit"
      :headers="{
        ...defaultHeaders,
        ...headers,
      }"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-change="change"
      :on-error="errorUpload"
      :on-success="successUpload"
      :on-exceed="onExceed"
      :on-progress="onProgress"
      :show-file-list="false"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

export default {
  props: {
    // 图片显示规则 fill|contain|cover|none|scale-down 详情连接 https://developer.mozilla.org/zh-CN/docs/Web/CSS/object-fit
    fit: {
      type: String,
      default: "scale-down",
    },
    // v-model 绑定值——图片地址，多张图片用 “,” 分隔，图片地址需为绝对路径
    videoUrls: {
      type: String,
      default: "",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 请求地址
    uploadImgUrl: {
      type: String,
      default: "/gateway/blade-resource/oss/endpoint/put-file",
    },
    // 请求头
    headers: {
      type: Object,
      default() {
        return {};
      },
    },
    // 最大上传个数
    limit: Number,
  },
  data() {
    return {
      isLoading: false,
      hideUpload: false, // 隐藏上传按钮
      fileList: [],
      loadList: [], // 正在上传中的列表
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      authorization: (state) => state.authorization,
      userInfo: (state) => state.app.userInfo,
    }),
    defaultHeaders() {
      let auth = this.userInfo.accessToken;
      let type = this.userInfo.tokenType;
      return {
        Authorization: this.authorization,
        "hc-auth": `${type} ${auth}`,
      };
    },
  },
  watch: {
    videoUrls: {
      handler(newValue) {
        console.log(newValue, "newValue");
        if (newValue) {
          let arr = newValue.split(",");
          // this.fileList = arr.map((v) => ({ url: v, status: "success" }));
          const fileUrls = this.fileList.map((v) => v.url);
          arr.map((v) => {
            console.log(v, fileUrls.includes(v));
            if (!fileUrls.includes(v)) {
              this.fileList.push({ url: v, status: "success" });
            }
          });
        } else {
          this.fileList = [];
        }
        // if (this.limit == 1) {
        //   let img = newValue ? [{ url: newValue, status: "success" }] : [];
        //   this.fileList = img;
        // } else {
        // }
        // console.log("我被触发了imgUrls", this.videoUrls, this.fileList);
        this.hideUpload = this.sFileList(this.fileList).length >= this.limit;
      },
      immediate: true,
    },
    fileList: {
      handler(val) {
        console.log(val, "vajdfka");
        const list = val.filter((v) => v.status === "success");
        this.updateFile(list);
      },
      deep: true,
    },
  },
  methods: {
    beforeUpload(file) {
      console.log(file, "mfile");
      // this.loadList.push({ ...file, percentage: 0 });
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    change(file, fileList) {
      this.fileList = fileList;
      this.hideUpload = this.sFileList(fileList).length >= this.limit;
    },
    // 文件超出个数限制时的钩子 function(files, fileList)
    onExceed() {
      this.$message({
        message: "上传超出最大限制个数",
        type: "warning",
      });
    },
    // 上传进度
    onProgress(event, file, fileList) {
      // const jd = file.percentage.toFixed(0);
      // if (file.percentage === 0) {
      //   this.loadList.push(file);
      // }
      let loadUrls = this.loadList.map((v) => v.uid);
      this.loadList.forEach((v) => {
        if (v.uid === file.uid) {
          // this.$set(v, "percentage", Number(file.percentage.toFixed(0)));
          // v = file;
          v.percentage = Number(file.percentage.toFixed(0));
        }
      });
      if (!loadUrls.includes(file.uid)) {
        this.loadList.push(file);
      }
      console.log(JSON.stringify(file), "jd---");
    },
    // 文件上传成功时的钩子
    successUpload(response, file, fileList) {
      console.log(file, JSON.stringify(fileList), "fsadfa");
      fileList.forEach((item) => {
        if (file.uid == item.uid) {
          item.url = response.data.link;
        }
      });
      this.loadList = _.cloneDeep(this.loadList).filter(
        (v) => v.uid !== file.uid
      );
      // if (this.loadList.length === 0) {
      //   this.updateFile(fileList);
      // }
    },
    // 文件上传失败时的钩子
    errorUpload(response, file, fileList) {
      let errorStr = response.toString();
      errorStr = errorStr.replace("Error: ", "");
      let errorObj = JSON.parse(errorStr);
      if (errorObj.code == "401") {
        this.$router.push({
          name: "login",
        });
      }
      this.loadList = _.cloneDeep(this.loadList).filter(
        (v) => v.uid !== file.uid
      );
      this.$message({
        type: "error",
        message: "上传出错",
        center: true,
      });
      // if (this.loadList.length === 0) {
      //   this.updateFile(fileList);
      // }
    },
    // 点击移除
    handleRemove(url) {
      // const imgUrlsArr = this.imgUrls.split(",").filter((v) => v !== url);
      // const imgUrls = imgUrlsArr.length ? imgUrlsArr.join(",") : "";
      // 删除符合回调函数 的数组元素
      let deepList = _.cloneDeep(this.fileList);
      _.remove(deepList, function (v) {
        console.log(v, "v---");
        return v.url === url;
      });
      this.fileList = deepList;
      console.log(this.fileList, "1241");
      // this.fileList.splice(index, 1);
      // this.$emit("update:imgUrls", imgUrls);
    },
    // 更新imgUrls状态
    updateFile(val) {
      if (this.limit == 1) {
        // let urlArr = val.map((item) => item.oUrl);
        if (val.length > 0) {
          // @arg imgUrls 图片列表 双向绑定 limit为1时String，>1时Array
          val[0].url && this.$emit("update:videoUrls", val[0].url);
        } else {
          this.$emit("update:videoUrls", "");
        }
      } else {
        let urlArr = [];
        val.forEach((item) => {
          // 文件状态为成功，代表已上传文件
          urlArr.push(item.url);
        });
        let urlStr = urlArr.join(",");
        this.$emit("update:videoUrls", urlStr);
      }
    },
    // 点击预览
    handlePictureCardPreview(url) {
      this.$previewBox([url]);
    },
    // status 值为 "success" 的文件
    sFileList(list) {
      return list.filter((v) => v.status === "success");
    },
  },
};
</script>
<style lang="less" scoped>
.v-upload {
  line-height: normal;
  .hide ::v-deep .el-upload--picture-card {
    display: none !important;
  }
  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
  ::v-deep .el-upload-list__item.is-ready {
    display: none;
  }
  .pic-upload {
    display: inline-block;
    overflow: hidden;
    margin: 0 8px 8px 0;
  }
  .pic-list {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
    > img {
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
    }
    > label {
      position: absolute;
      display: block;
      right: -15px;
      top: -6px;
      width: 40px;
      height: 24px;
      background: #13ce66;
      text-align: center;
      transform: rotate(45deg);
      line-height: 30px;
      > i {
        font-size: 12px;
        margin-bottom: 11px;
        transform: rotate(-45deg);
        color: #fff;
      }
    }
    &:hover .upload-list__item-actions {
      opacity: 1;
    }
    .upload-list__item-actions {
      opacity: 0;
      position: absolute;
      width: 30%;
      height: 30%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      cursor: default;
      text-align: center;
      color: #fff;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      line-height: 1.8;
      &::after {
        display: inline-block;
        content: "";
        height: 100%;
        vertical-align: middle;
      }
      > span {
        display: inline-block;
        cursor: pointer;
        & + span {
          margin-left: 15px;
        }
      }
    }
  }
  .pic-list .progress {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
