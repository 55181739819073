<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="total, slot, prev, pager, next, jumper"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :total="total"
      v-bind="$attrs"
      @current-change="handleCurrentChange"
    >
      <el-select
        class="page-size-select"
        size="mini"
        v-model="limit"
        allow-create
        placeholder="可输入"
        default-first-option
        @change="handleSizeChange"
      >
        <el-option
          v-for="(item, index) in pageSizes"
          :key="index"
          :value="item"
          :label="item"
        />
      </el-select>
      <span class="page-size-text" v-if="limit != '全部'">条/页</span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    curPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, "全部"];
      },
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7,
    },
    // layout: {
    //   type: String,
    //   default: "total, sizes, prev, pager, next, jumper",
    // },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.curPage;
      },
      set(val) {
        this.$emit("update:curPage", val);
      },
    },
    limit: {
      get() {
        if (this.pageSize == "9999") {
          return "全部";
        }
        return this.pageSize;
      },
      set(val) {
        if (val == "全部") {
          this.$emit("update:pageSize", 9999);
        } else {
          this.$emit("update:pageSize", val);
        }
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1;
      }
      if (val == "全部") {
        this.$emit("handleSizeChange", 9999);
        return;
      }
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
}
.pagination-container.hidden {
  display: none;
}

.page-size-select ::v-deep .el-input {
  width: 65px;
  margin: 0 5px;
}

.page-size-text {
  font-weight: normal;
  color: #606266;
}
</style>
