<template>
  <div class="baseInfo">
    <div class="baseInfo-title">基础信息</div>
    <div class="baseInfo-card">
      <table style="border-collapse: separate">
        <tbody>
          <tr class="baseInfo-first">
            <td colspan="1">
              <div class="baseInfo-list">
                <span class="baseInfo-label"> 接口名称： </span>
                <span class="baseInfo-content">
                  {{ baseInfoList.apiName }}
                </span>
              </div>
            </td>
            <td colspan="1">
              <div class="baseInfo-list">
                <span class="baseInfo-label"> 接口地址： </span>
                <span class="baseInfo-content">
                  {{ baseInfoList.apiUrl }}
                </span>
              </div>
            </td>
            <td colspan="1">
              <div class="baseInfo-list">
                <span class="baseInfo-label"> 上报周期： </span>
                <span class="baseInfo-content">
                  {{ baseInfoList.reportCycle }}
                </span>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td colspan="1">
              <div class="baseInfo-list">
                <span class="baseInfo-label"> 最后一次成功上报时间：</span>
                <span class="baseInfo-content"> 2023-04-01 00:00:07 </span>
              </div>
            </td>
            <td colspan="1">
              <div class="baseInfo-list">
                <span class="baseInfo-label"> 总计上报次数：</span>
                <span class="baseInfo-content"> 6</span>
              </div>
            </td>
            <td colspan="1">
              <div class="baseInfo-list">
                <span class="baseInfo-label"> 上报成功次数/上报失败次数：</span>
                <span class="baseInfo-content">6/0</span>
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <div class="tag">{{ baseInfoList.reportCycle }}</div>
  </div>
</template>

<script>
let that;
export default {
  name: "baseInfo",
  components: {},
  props: {
    baseInfoList: {
      type: Object,
      default: () => {},
    },
  },
  beforeCreate() {
    that = this;
  },
  data() {
    return {};
  },
  filters: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.baseInfo {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 65px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  .baseInfo-title {
    margin: 10px 0;
    flex: auto;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .baseInfo-card {
    width: 100%;
    table {
      width: 100%;
      // tr {
      //   width: 100%;
      //   display: flex;
      //   justify-content: space-between;
      //   td {
      //     flex: 1;
      //   }
      // }

      .baseInfo-list {
        display: flex;
        align-items: center;
        .baseInfo-label {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5715;
          text-align: start;
        }
        .baseInfo-content {
          flex: 1;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          line-height: 1.5715;
          word-break: break-word;
          overflow-wrap: break-word;
        }
      }
    }
  }
  .tag {
    cursor: pointer;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    position: absolute;
    left: -8px;
    top: 8px;
    padding: 5px;
    color: #fff;
    white-space: nowrap;
    background-color: #1f5ad8;
    border-radius: 2px;
    border-bottom-right-radius: 0;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 0;
      height: 0;
      border-color: #bfbfbf transparent;
      border-width: 8px 0 0 8px;
      border-style: solid;
    }
  }
}
</style>
