const routes = [
  {
    path: "futureEdu/bestBeautiful",
    name: "bestBeautiful",
    meta: {
      title: "最美上学路",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/bestBeautiful/index"], resolve),
  },
  {
    path: "futureEdu/applyActivityQSN",
    name: "applyActivityQSN",
    meta: {
      title: "青少年志愿服务",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/applyActivity/index"], resolve),
  },
  {
    path: "futureEdu/addActivityQSN",
    name: "addActivityQSN",
    meta: {
      echo: "futureEdu/applyActivityQSN",
      title: "活动编辑",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/applyActivity/addActivityQSN.vue"], resolve),
  },
  {
    path: "futureEdu/activityFeedbackQSN",
    name: "activityFeedbackQSN",
    meta: {
      echo: "futureEdu/applyActivityQSN",
      title: "活动反馈",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/applyActivity/activityFeedbackQSN.vue",
      ], resolve),
  },
  {
    path: "futureEdu/activityApplicantsQSN",
    name: "activityApplicantsQSN",
    meta: {
      echo: "futureEdu/applyActivityQSN",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/applyActivity/activityApplicantsQSN.vue",
      ], resolve),
  },
  {
    path: "futureEdu/chapterList",
    name: "chapterList",
    meta: {
      title: "章节列表",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/chapterList.vue"], resolve),
  },
  {
    path: "futureEdu/chapterForm",
    name: "chapterForm",
    meta: {
      title: "新增章节",
      echo: "futureEdu/chapterList",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/chapterForm.vue"], resolve),
  },
  {
    path: "futureEdu/courseList",
    name: "courseList",
    meta: {
      title: "课程列表",
      echo: "futureEdu/chapterList",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseList.vue"], resolve),
  },
  {
    path: "futureEdu/courseForm",
    name: "courseForm",
    meta: {
      title: "新增课程",
      echo: "futureEdu/chapterList",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseForm.vue"], resolve),
  },
  /** 明珠托幼 */
  {
    path: "futureEdu/entrustChild",
    name: "entrustChild",
    meta: {
      title: "托幼课程",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/index.vue"], resolve),
  },
  {
    path: "futureEdu/entrustChildUser",
    name: "entrustChildUser",
    meta: {
      title: "意见反馈",
      echo: "futureEdu/entrustChild",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildUser.vue"], resolve),
  },
  {
    path: "futureEdu/entrustChildUserForm",
    name: "entrustChildUserForm",
    meta: {
      title: "意见反馈表单",
      echo: "futureEdu/entrustChild",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/entrustChild/entrustChildUserForm.vue",
      ], resolve),
  },
  {
    path: "futureEdu/entrustChildForm",
    name: "entrustChildForm",
    meta: {
      title: "课程表单",
      echo: "futureEdu/entrustChild",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildForm.vue"], resolve),
  },
  // 法治课堂-知识库管理
  {
    path: "futureEdu/knowledgeBase",
    name: "knowledgeBase",
    meta: {
      title: "知识库管理",
      echo: "futureEdu/knowledgeBase",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/ruleOfLawClassroom/knowledgeBase.vue",
      ], resolve),
  },
  {
    path: "futureEdu/knowledgeBaseForm",
    name: "knowledgeBaseForm",
    meta: {
      title: "知识库管理",
      echo: "futureEdu/knowledgeBaseForm",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/ruleOfLawClassroom/knowledgeBaseForm.vue",
      ], resolve),
  },
  // 法治课堂-留言回复
  {
    path: "futureEdu/messageReply",
    name: "messageReply",
    meta: {
      title: "留言回复",
      echo: "futureEdu/messageReply",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/ruleOfLawClassroom/messageReply.vue",
      ], resolve),
  },
  // 法治课堂-法制活动
  {
    path: "futureEdu/applyActivityfz",
    name: "applyActivityfz",
    meta: {
      title: "法制活动",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/ruleOfLawClassroom/index"], resolve),
  },
  {
    path: "futureEdu/addActivityfz",
    name: "addActivityfz",
    meta: {
      echo: "futureEdu/applyActivityfz",
      title: "活动编辑",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/ruleOfLawClassroom/addActivity.vue",
      ], resolve),
  },
  {
    path: "futureEdu/activityFeedbackfz",
    name: "activityFeedbackfz",
    meta: {
      echo: "futureEdu/applyActivityfz",
      title: "活动反馈",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/ruleOfLawClassroom/activityFeedback.vue",
      ], resolve),
  },
  {
    path: "futureEdu/activityApplicantsfz",
    name: "activityApplicantsfz",
    meta: {
      echo: "futureEdu/applyActivityfz",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/ruleOfLawClassroom/activityApplicants.vue",
      ], resolve),
  },
];

export default routes;
