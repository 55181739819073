<template>
  <div class="v-control v-input">
    <label v-if="label" v-text="label"></label>
    <el-input
      ref="input"
      v-model="model"
      :placeholder="placeholder"
      :size="size"
      :name="name"
      :type="type"
      :clearable="clearable"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :min="min"
      :max="max"
      :prefix-icon="prefixIcon"
      :suffix-icon="suffixIcon"
      v-bind="{ ...bind, ...nativeProps }"
      :maxlength="maxlength"
      :tabindex="tabindex"
      :rows="textAreaRows"
      :show-word-limit="isshowWordLimit"
      :readonly="isReadonly"
      @keyup="keyupEvent"
      @focus="focusEvent"
      @clear="clearEvent"
      @blur="blurEvent"
    >
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </el-input>
  </div>
</template>

<script>
import Vue from "vue";
import { Input } from "element-ui";
Vue.use(Input);
export default {
  props: {
    tabindex: String,
    // input 原生属性
    min: String,
    // input 原生属性
    max: {
      type: [String, Number],
      default: "",
    },
    prefixIcon: String,
    suffixIcon: String,
    maxlength: {
      type: Number,
      default: 1000,
    },
    // 输入框宽度
    width: {
      type: Number,
      // `144`
      default: 188,
    },
    nativeProps: {},
    size: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //输入框行数,只对 type="textarea" 有效
    textAreaRows: {
      type: Number,
      default: 2,
    },
    //是否显示输入字数统计，只在 type = "text" 或 type = "textarea" 时有效
    isshowWordLimit: {
      type: Boolean,
      default: false,
    },
    //是否可读
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputWidth: this.width,
      model: this.value,
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {
    this.bind = {
      style: {
        width: this.inputWidth + "px",
      },
    };
  },
  methods: {
    focus() {
      // 获取焦点
      this.$refs.input.focus();
    },
    blur() {
      // 失去焦点
      this.$refs.input.blur();
    },
    clearEvent() {
      this.$emit("clear");
    },
    focusEvent() {
      this.$emit("focus");
    },
    blurEvent() {
      this.$emit("blur");
    },
    keyupEvent() {
      this.$emit("keyup");
    },
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less">
.v-input {
  label {
    font-size: 14px;
    columns: #999;
    margin-right: 10px;
  }
}
</style>
