<template>
  <el-drawer
    :visible.sync="drawer"
    :title="title"
    :size="size"
    :direction="direction"
    :wrapperClosable="wrapperClosable"
    :append-to-body="appendToBody"
  >
    <!-- 自定义标题 -->
    <!-- <template #title>
      <slot name="title"></slot>
    </template> -->
    <slot></slot>
  </el-drawer>
</template>

<script>
import Vue from "vue";
import { Drawer } from "element-ui";
Vue.use(Drawer);
export default {
  props: {
    //是否可见
    isShowDrawer: {
      type: Boolean,
      default: false,
    },
    //点击遮罩层是否可以关闭 Drawer
    wrapperClosable: {
      type: Boolean,
      default: true,
    },
    //点击遮罩层是否可以关闭 Drawer
    appendToBody: {
      type: Boolean,
      default: false,
    },
    //是否显示底部按钮
    isShowFooterBtn: {
      type: Boolean,
      default: true,
    },
    // 标题
    title: {
      type: String,
      default: "",
    },
    //Drawer 窗体的大小,
    size: {
      type: [String, Number],
      default: "70%",
    },
    // 抽屉的方向
    direction: {
      type: String,
      default: "rtl",
    },
  },
  computed: {
    drawer: {
      get() {
        return this.isShowDrawer;
      },
      set(v) {
        this.$emit("changeDrawer", v);
      },
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="less">
::v-deep .el-drawer__header {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
::v-deep .el-drawer__body {
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
</style>
