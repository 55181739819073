<!--
  组件暂时不支持手动上传
-->
<template>
  <div class="v-uploader-wrapper" :style="{ width }">
    <div class="v-controls">
      <el-upload
        ref="upload"
        class="upload-demo"
        :style="fileStyle"
        :disabled="disabled"
        :data="extraData"
        :action="action"
        :multiple="multiple"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-exceed="onExceed"
        :drag="false"
        :auto-upload="autoUpload"
        :on-success="successUpload"
        :on-error="errorUpload"
        :list-type="listType"
        :on-change="onChange"
        :headers="{
          ...defaultHeaders,
          ...headers,
        }"
        :http-request="httpRequest"
        :name="name"
        :limit="limitValue"
        :accept="accept"
      >
        <div class="box" @click="btnClick">
          <div
            class="box-top"
            v-if="!disabled"
            v-show="limit - valueFormat.length - uploadListShow.length > 0"
          >
            <slot>
              <v-button v-loading="loading" type="primary">{{ text }}</v-button>
              <div v-if="showMessage" class="fill-empty" @click.stop>
                <template v-if="limit !== Infinity">
                  &nbsp;剩余可上传数为&nbsp;{{
                    /* 禁止这片空白区域被点击 */ limitValue
                  }}，超出将取消本次上传!
                </template>
                <template v-else>&nbsp;{{ tip }}</template>
              </div>
            </slot>
          </div>
          <div class="box-content" @click.stop>
            <slot name="tip" v-if="limit !== Infinity">
              <div class="tip" v-show="!!tip">&nbsp;{{ tip }}</div>
            </slot>
            <div
              class="file-list"
              :style="{ width: listWidth }"
              v-for="(item, index) in uploadListShow"
              :key="`upload_${index}`"
              @click.stop
            >
              <div class="pre-icon"><i class="el-icon-loading" /></div>
              <div
                class="file-name"
                v-if="item !== '' && typeof item === 'object'"
              >
                文件正在上传中...
              </div>
              <div
                class="file-name"
                v-else-if="typeof item === 'string' && item !== ''"
              >
                文件上传成功,等待其他文件上传完成中...
              </div>
              <div class="file-name" v-else>
                文件上传失败,等待其他文件上传完成中...
              </div>
            </div>
            <div
              class="file-list"
              :style="{ width: listWidth }"
              v-for="(item, index) in fileListShow"
              :key="index"
              @click.stop="previewUpload"
            >
              <div class="pre-icon"><i class="el-icon-document" /></div>
              <div class="file-name" :title="item.name">{{ item.name }}</div>
              <div class="down file-icon" @click.stop="downFile(item)">
                <i class="el-icon-download" />
              </div>
              <div
                class="delete file-icon"
                v-if="!disabled"
                @click.stop="deleteFile(index, item)"
              >
                <i class="el-icon-close" />
              </div>
            </div>
          </div>
        </div>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
import { downloadHelper } from "@/utils/func";
import emitter from "element-ui/src/mixins/emitter";
// @group 基础组件
// @title Uploader 上传组件
export default {
  name: "v-uploadFile",
  mixins: [emitter],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    autoUpload: {
      type: Boolean,
      default: true,
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: "添加附件",
    },
    value: [Array, String], // v-model绑定值
    tip: String, // 提示文字
    // 上传时附带的额外参数
    extraData: {
      type: Object,
      // `{}`
      default: () => {
        return {
          type: "",
        };
      },
    },
    // 必选参数，上传的地址
    action: {
      type: String,
      default: "/gateway/blade-resource/oss/endpoint/put-file/",
    },
    showFileList: {
      type: Boolean,
      default: false,
    },
    // 是否多选
    multiple: {
      type: Boolean,
      // `true`
      default: true,
    },
    // 接收的文件类型
    accept: {
      type: String,
    },
    // 上传文件大小  单位M
    fileSize: {
      type: Number,
      // `50`
      default: 50,
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      // `Infinity`
      default: Infinity,
    },
    // showFileList为true是所显示的type
    listType: {
      // `'text'` / `'picture'` / `'picture-card'`
      type: String,
      // `text`
      default: "text",
    },
    // 是否禁用
    disabled: Boolean,
    // 文件列表移除文件时的钩子 function(file, fileList)
    onSuccess: Function,
    // 文件上传失败时的钩子function(err, file, fileList)
    onError: Function,
    // 点击文件列表中已上传的文件时的钩子function(file)
    onPreview: Function,
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止上传。function(file, fileList)
    beforeRemove: Function,
    // 上传文件前钩子
    beforeUpload: Function,
    // 是否支持排序 limit > 1 时可选
    isOrder: {
      type: Boolean,
      // `false`
      default: false,
    },
    setHttpRequest: Function,
    // 请求头部
    headers: Object,
    // 是否弹出错误提示
    isAlertErrorMsg: {
      type: Boolean,
      // `true`
      default: true,
    },
    // 数据返回url前缀
    prefixUrl: String,
    // 上传的文件字段名
    name: {
      type: String,
      // `file`
      default: "file",
    },
    // 显示的按钮
    operate: {
      type: String,
      // `'all'` / `'delete'` / `'none'`
      default: "all",
    },
    fileStyle: {
      type: [Object, Array, String],
    },
    width: {
      // 内容区的宽度
      type: String,
      default: "100%",
    },
    listWidth: {
      // 内容区的宽度
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      fileName: "",
      loading: false,
      percentage: 0,
      onOffNum: true, // 上传文件数量的开关, true 超出数量时提示
      fileList: [],
      uploadListShow: [], // 上传中的文件
      playTime: 0,
    };
  },
  computed: {
    ...mapState({
      authorization: (state) => state.authorization,
      userInfo: (state) => state.app.userInfo,
    }),
    valueFormat() {
      return Array.isArray(this.value)
        ? this.value
        : this.value
        ? this.value.split(",")
        : [];
    },
    defaultHeaders() {
      let auth = this.userInfo.accessToken;
      let type = this.userInfo.tokenType;
      return {
        Authorization: this.authorization,
        "blade-auth": `${type} ${auth}`,
      };
    },
    limitValue() {
      return this.limit - this.valueFormat.length;
    },
    fileListShow() {
      return this.valueFormat.map((v) => {
        console.log("v=====>", v);
        let vArr = v.split("/");
        let name = vArr[vArr.length - 1];
        return {
          name,
          url: v,
        };
      });
    },
  },
  filters: {
    fileSize(val) {
      let text = "";
      if (val < 1024000) {
        text = `${(val / 1024).toFixed(2)}K`;
      } else if (val < 1024000 * 1024) {
        text = `${(val / 1024 / 1024).toFixed(2)}M`;
      } else {
        text = `${(val / 1024 / 1024 / 1024).toFixed(2)}KM`;
      }
      return text;
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      // 上传前先进行验证
      // 一次选择多个文件将会重复调用该方法
      if (file.name.substring(file.name.lastIndexOf(".") + 1) == "mp4") {
        let _this = this;
        let url = URL.createObjectURL(file);
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", function () {
          _this.playTime = audioElement.duration.toFixed(); //playTime就是当前视频长度
        });
      }

      return this.baseVaild(file);
    },
    baseVaild(file) {
      let _this = this;
      const isSizeover = file.size / 1024 / 1024 < _this.fileSize;
      const isNumNoOver = _this.valueFormat.length < _this.limit;
      // console.log(this.onOffSize, this.onOffNum, '验证')
      const isFormat = this.accept
        ? this.accept.includes(
            file.name.substring(file.name.lastIndexOf(".") + 1)
          )
        : true;
      if (!isFormat) {
        _this.$message("请上传正确格式文件");
      }
      if (!isSizeover) {
        setTimeout(() => {
          _this.$message(`${file.name}文件的大小超过${_this.fileSize}M`);
        });
      }
      if (!isNumNoOver && this.onOffNum) {
        _this.$message(`数量超出，不能超过${_this.limit}个`);
        this.onOffNum = false;
      }
      // console.log(file, isSizeover, 'isSizeover && isNumNoOver')
      return isSizeover && isNumNoOver && isFormat;
    },
    // 文件超出个数限制时的钩子 function(files, fileList)
    onExceed() {
      this.$message({
        message: "上传超出最大限制个数",
        type: "warning",
      });
    },
    /** 功能未完成 */
    successUpload(response, file, fileList) {
      // 文件上传成功时的钩子
      // console.log('触发上传成功的钩子')
      this.onSuccess && this.onSuccess(response, file, fileList);
    },
    errorUpload(response, file, fileList) {
      let errorStr = response.toString();
      errorStr = errorStr.replace("Error: ", "");
      let errorObj = JSON.parse(errorStr);
      if (errorObj.code == "401") {
        this.$router.push({
          name: "login",
        });
      }
      // 文件上传失败时的钩子
      // console.log('触发上传失败的钩子')
      this.onError && this.onError(response, file, fileList);
    },
    previewUpload(file) {
      // 点击文件列表中已上传的文件时的钩子
      // console.log(file)
      this.onPreview && this.onPreview(file);
    },
    submitUpload() {
      // 上传至服务器，手动上传时需要
      this.$refs.upload.submit();
    },
    clearFiles() {
      // 上传至服务器，手动上传时需要
      this.$refs.upload.clearFiles();
    },
    /** end */
    progressUpload(event, file, fileList) {
      // 文件上传时的钩子
      // console.log('触发文件上传时的钩子')
    },
    btnClick() {
      this.clearFiles();
      this.onOffNum = true;
    },
    httpRequest(file) {
      console.log("file=====>", file);
      // 替换原有请求
      if (this.setHttpRequest) {
        this.setHttpRequest(file.file, this);
        return;
      }
      const len = this.uploadListShow.unshift(file);
      const formData = new FormData();
      formData.append("file", file.file);
      // formData.append("isCover", this.isCover);
      // formData.append("filePath", this.filePath);
      this.$axios
        .post(this.action, formData, {
          headers: {
            ...this.defaultHeaders,
            ...this.headers,
          },
        })
        .then((res) => {
          const index = this.uploadListShow.length - len;
          if (res.code === 200 && res.success && res.data) {
            this.uploadListShow.splice(index, 1, res.data.link);
          } else {
            this.uploadListShow.splice(index, 1, "");
          }
        })
        .catch(() => {
          const index = this.uploadListShow.length - len;
          this.uploadListShow.splice(index, 1, "");
        })
        .finally(() => {
          let result = false;
          this.uploadListShow.forEach((v) => {
            if (typeof v !== "string") {
              result = true;
            }
          });
          if (result) {
            return;
          }
          const oldArr = [...this.valueFormat];
          // const newArr = [res.data]
          const newArr = this.uploadListShow.filter(
            (v) => v !== "" && typeof v !== "object"
          );
          const valueResult = [...newArr, ...oldArr];
          this.$emit(
            "input",
            Array.isArray(this.value) ? valueResult : valueResult.join(","),
            file,
            this.playTime
          );
          // 用于解决上传组件无表单错误提示的问题，未测试上传多张图片的情况
          const timer = setTimeout(() => {
            this.dispatch("ElFormItem", "el.form.change", valueResult);
            clearTimeout(timer);
          }, 100);
          this.uploadListShow = [];
        });
    },
    onChange(file, fileList) {
      // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
      this.fileList = [...this.fileList, ...fileList];
      // console.log(file, fileList)
      // console.log(fileList, '文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用')
      // this.httpRequest([file])
    },
    async deleteFile(index, item) {
      let result = this.beforeRemove
        ? await this.beforeRemove(index, item)
        : true;
      if (result) {
        // 代码
        const newArr = _.cloneDeep(this.valueFormat);
        newArr.splice(index, 1);
        this.$emit(
          "input",
          Array.isArray(this.value) ? newArr : newArr.join(",")
        );
        // 用于解决上传组件无表单错误提示的问题，未测试上传多张图片的情况
        const timer = setTimeout(() => {
          this.dispatch("ElFormItem", "el.form.change", newArr);
          clearTimeout(timer);
        }, 100);
        this.onOffNum = true;
      }
    },
    downFile(item) {
      let url = item.url;
      let fileName = item.name;
      downloadHelper.downloadBySrc({
        src: url,
        rel: "noopener noreferrer",
        target: "_blank",
        fileName,
      });
    },
  },
};
</script>

<style scoped lang="less">
.v-uploader-wrapper {
  ::v-deep .el-upload {
    width: 100%;
    display: block;
    text-align: left;
    .box {
      // position: relative;
      width: 100%;
      .box-top {
        display: flex;
        .fill-empty {
          display: flex;
          align-items: center;
          color: #606266;
          flex: 1;
          cursor: default;
        }
        // position: absolute;
        // left: 0;
        // top: 0;
        // display: inline-block;
      }
      .box-content {
        display: flex;
        flex-direction: column;
        &::after {
          content: "";
          display: block;
          clear: both;
        }
        // padding-top: 20px;
        .tip {
          cursor: default;
          color: #606266;
        }
        // 插槽内容
        .file-list {
          float: left;
          display: flex;
          align-items: center;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          margin: 5px;
          color: #606266;
          &:hover {
            background-color: #f5f7fa;
            .file-name {
              color: #409eff;
            }
            .file-icon {
              display: flex;
            }
          }
          .pre-icon {
            width: 14px;
            height: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 2px;
          }
          .file-name {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            // margin-left: 10px;
            margin-left: 10px;
          }
          .file-icon {
            width: 40px;
            height: 0.4px;
            display: none;
            align-items: center;
            justify-content: center;
            margin-left: 2px;
            opacity: 0.75;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
